import request from "../utils/request";

/**
 * 加入我们表单提交
 */
 export function save(data) {
  return request({
    // url: '/api/customer/save',
    url: 'https://www.dalligent.com/api/customer/save',
    method: 'post',
    data
  })
}
