// 返回按钮
import './index.scss'
import { Link } from "react-router-dom"
import { Collapse } from 'antd'
const { Panel } = Collapse

function Foot() {
  const goTo = (i) => {
    const ids = document.getElementById('mySwiperId')
    if (ids) ids.swiper.slideTo(i || 0) // i从0开始
  }
  /*
  * goTo：在首页时，点击跳转，控制swiper切换到指定的slide
  * homeIndex：不在首页时，点击跳转到首页，控制swiper切换到指定的slide
  * companyIndex：点击跳转到公司简介时，控制第几项高亮
  * */
  const link1 = (isTitle) => {
    if (isTitle === 'titlePC') return (<div className="title" onClick={() => { goTo(0) }}>
      <Link to={{ pathname: '/' }}>关于我们</Link></div>)
    if (isTitle) return '关于我们'
    return (<>
      <div onClick={() => { goTo(1) }}><Link to={{ pathname: '/', homeIndex: 1 }}>公司简介</Link></div>
      <div onClick={() => { goTo(2) }}><Link to={{ pathname: '/', homeIndex: 2 }}>发展历程</Link></div>
      <div onClick={() => { goTo(3) }}><Link to={{ pathname: '/', homeIndex: 3 }}>技术成果</Link></div>
      <div onClick={() => { goTo(4) }}><Link to={{ pathname: '/', homeIndex: 4 }}>行业资讯</Link></div>
    </>)
  }
  const link2 = (isTitle) => {
    if (isTitle === 'titlePC') return (<div className="title" onClick={() => { goTo(1) }}>
      <Link to={{ pathname: '/', homeIndex: 1 }}>集团研发中心</Link></div>)
    if (isTitle) return '集团研发中心'
    return (<>
      <div onClick={() => { goTo(1) }}><Link to={{ pathname: '/', homeIndex: 1, companyIndex: 1 }}>新加坡</Link></div>
      <div onClick={() => { goTo(1) }}><Link to={{ pathname: '/', homeIndex: 1, companyIndex: 2 }}>印尼·雅加达</Link></div>
      <div onClick={() => { goTo(1) }}><Link to={{ pathname: '/', homeIndex: 1, companyIndex: 0 }}>中国·北京</Link></div>
    </>)
  }
  const link3 = (isTitle) => {
    if (isTitle === 'titlePC') return (<div className="title" onClick={() => { goTo(1) }}>
      <Link to={{ pathname: '/', homeIndex: 3 }}>技术成果</Link></div>)
    if (isTitle) return '技术成果'
    return (<>
      <div><Link to={{ pathname: '/productdetail', search: `?id=1` }}>云原生应用平台</Link></div>
      <div><Link to={{ pathname: '/productdetail', search: `?id=2` }}>云原生数据平台</Link></div>
      <div><Link to={{ pathname: '/productdetail', search: `?id=3` }}>自研数据库</Link></div>
    </>)
  }
  const link4 = (isTitle) => {
    if (isTitle === 'titlePC') return (<div className="title"><Link to={{ pathname: '/product' }}>我们的产品</Link></div>)
    if (isTitle) return '我们的产品'
    return (<>
      <div><a href="/product#kupu-recruitment">KUPU招聘平台</a></div>
      <div><a href="/product#kupu-pc">KUPU学院</a></div>
      <div><a href="/product#kupu-bi">KUPU-BI后台管理系统</a></div>
      <div><a href="/product#kupu-man">KUPU运营管理平台</a></div>
      <div><a href="/product#KUPU-KOS">KUPU—KOS</a></div>
    </>)
  }
  const link5 = (isTitle) => {
    if (isTitle === 'titlePC') return (<div className="title"><Link to={{ pathname: '/joinus' }}>加入我们</Link></div>)
    if (isTitle) return '加入我们'
    return (<>
      <div><a href="/joinus">投递简历</a></div>
    </>)
  }
  const link6 = (isTitle) => {
    if (isTitle) return '合作咨询'
    return (<>
      <div><a href="mailto:ds-marketing@dalligent.com">ds-marketing@dalligent.com</a></div>
    </>)
  }
  const link7 = (isTitle) => {
    if (isTitle) return '人才招聘'
    return (<>
      <div><a href="mailto:ds-hr@dalligent.com">ds-hr@dalligent.com</a></div>
    </>)
  }
  // const qiyeetech = () => {
  //   return (<>
  //     <a href="https://qiyeetech.com/" target='_blank'>奇蚁科技</a>
  //   </>)
  // }
  const icp = '京ICP备20027653号-1'
  return (
    <>
      <div className='components-foot'>
        <div className='foot-content'>
          <div className='foot-list'>
            <div className="item small">
              <img src={require('../../static/logo_blue.png')} alt="" className='logo' />
            </div>
            <div className="item small">
              { link1('titlePC') }
              { link1() }
            </div>
            <div className="item small">
              { link2('titlePC') }
              { link2() }
            </div>
            <div className="item small">
              { link3('titlePC') }
              { link3() }
            </div>
            <div className="item small">
              { link4('titlePC') }
              { link4() }
            </div>
            <div className="item small">
              { link5('titlePC') }
            </div>
          </div>

          <div className='content-title'>联系我们</div>
          <div className='foot-list'>
            {/*<div className="item second">
              <div className="title">{ link5('title') }</div>
              { link5() }
            </div>*/}
            <div className="item second">
              <div className="title">{ link6('title') }</div>
              { link6() }
            </div>
            <div className="item second">
              <div className="title">{ link7('title') }</div>
              { link7() }
            </div>
          </div>
          {/*<div className='qr-code'>
            <img src={require('../../static/logo_blue.png')} alt=""/>
          </div>*/}
          {/*<div className='link'>
            <span>友情链接：</span>
            { qiyeetech() }
          </div>*/}
        </div>
        <div className="icp">
          <a href="http://beian.miit.gov.cn" target="_blank" rel="noreferrer">{ icp }</a></div>
      </div>
      <div className='components-foot-mobile'>
        {/*<div className='contact-us'>
          <img src={require('../../static/qr_code.png')} alt=""/>
          <span>联系我们</span>
        </div>*/}
        <Collapse
          bordered={false}
          accordion
          defaultActiveKey={[]}
          expandIcon={({ isActive }) =>
            <img src={require('../../static/bottom_arrow.png')} alt="" className={isActive ? 'rotate' : ''}  />}
        >
          <Panel header={ link1('title') } key="1">
            { link1() }
          </Panel>
          <Panel header={ link2('title') } key="2">
            { link2() }
          </Panel>
          <Panel header={ link3('title') } key="3">
            { link3() }
          </Panel>
          <Panel header={ link4('title') } key="4">
            { link4() }
          </Panel>
          <Panel header={ link5('title') } key="5">
            { link5() }
          </Panel>
          <Panel header={ link6('title') } key="6">
            { link6() }
          </Panel>
          <Panel header={ link7('title') } key="7">
            { link7() }
          </Panel>
        </Collapse>
        <div className="icp">
          {/*<div>
            <span>友情链接：</span>
            { qiyeetech() }
          </div>*/}
          <div>{ icp }</div>
        </div>
      </div>
    </>
  )
}

export default Foot
