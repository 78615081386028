import { useEffect } from 'react'
import  { changeIndex }  from '../store/actions'

// 页面滚动修改header的class
export const PageRender = function (props) {
  const { changeIndex } = props
  useEffect(() => {
    window.addEventListener("scroll",handleScroll)
    changeIndex(0)
  }, [])
  const handleScroll = (e) => {
    const scrollTop = e.target.scrollingElement.scrollTop
    if(Number(scrollTop) === 0) {
      changeIndex(0)
    } else {
      changeIndex(1)
    }
  }
}

// 此页面只有一种header的class
export const PageRenderFixed = function (props, type) {
  const { changeIndex } = props
  changeIndex(type)
}

export const mapState = (state) => ({
  activeIndex: state.activeIndex
})
export const mapDispatch = (dispatch) => ({
  changeIndex(index) {
    dispatch(changeIndex(index))
  }
})
