import top from './img/top.png'
import center from './img/center.png'
import leftBottom from './img/leftBottom.png'
import rightBottom from './img/rightBottom.png'
// import cardBg from './img/card-bg.png'
import item1 from './img/item1.png'
import item2 from './img/item2.png'
import item3 from './img/item3.png'
import './index.scss'
import { useEffect, useState } from 'react'

function CompanyProfile(props) {
  const data= [
    {
      img: item1,
      title: '研发中心',
      name: '北京',
      location1: `北京市大兴区国锐广场B座8层`,
      location2: '',
    },
    {
      img: item2,
      title: '运营总部',
      name: '新加坡',
      location1: '108 Pasir Panjang Road #06-00',
      location2: 'Golden Agri Plaza',
      location3: 'Singapore 118535'
    },
    {
      img: item3,
      title: '推广中心',
      name: '印度尼西亚',
      location1: 'Sinar Mas Land Plaza,',
      location2: 'Tower 1, 11th FL JI M.H.Thamrin No.51',
      location3: 'Jakarta 10350, Indonesia'
    }
  ]
  const [indexActive, setIndexActive] = useState(0)
  const changeActive = (i) => {
    setIndexActive(i)
  }
  useEffect(() => {
    changeActive(props.index || 0)
  }, [props.index])
  return (
    <div className='company-profile'>
      <div className="top">
        <img src={top} alt="" />
      </div>
      <div className="center">
        <img src={center} alt="" />
      </div>
      <div className="right-bottom">
        <img src={rightBottom} alt="" />
      </div>

      <div className='company-profile-left'>
        <div className='page-title'>公司简介</div>
        <div className='page-title-translate'>
          <div>COMPANY</div>
          <div>PROFILE</div>
        </div>
        <div className='tips'>DS集团全称“Dalligent Solutions”，以技术生态重塑东南亚劳动人力市场的雇佣关系，集合云计算、大数据、人工智能等技术，服务于应用场景细微复杂、千变万化的大众灵活用工市场，重塑雇主端、求职端以及用工服务的生态关系，以推动千亿美金量级的商业革命。</div>
        <div className='left-card'>
          {/*<img src={cardBg} alt="" className='card-bg' />*/}
          <img src={leftBottom} alt="" className='left-bottom' />
          <div>
            <div className="card-title">愿景</div>
            <div className="card-text">帮助1亿东南亚劳动者<br/>获得更好的工作和生活</div>
          </div>
        </div>
      </div>

      <div className={'company-profile-list'}>
      {
        data.map((item, index) => {
          return  <div key={index} className={ indexActive === index ? 'company-profile-item hover' : 'company-profile-item'} onClick={() => setIndexActive(index)} onMouseOver={ () => changeActive(index)}>
            <img src={item.img} alt="" className="item-img" />
            <div className='text'>
              <div className="title">{ item.title }</div>
              <div className="name">{ item.name }</div>
              <div className="location">
                <span>{ item.location1 }</span>
                <span>{ item.location2 }</span>
                <span>{ item.location3 }</span>
              </div>
            </div>
          </div>
        })
      }
      </div>
    </div>
  )
}

export default CompanyProfile
