import React, { useState, useEffect } from 'react'
import { save } from '../../api/index'
import './index.scss'
import banner from '../../static/jionUs/banner.png'
import bannerH5 from '../../static/jionUs/join-form-h5.png'
import { Form, Input, Button, Select, Row, Col, Upload, message } from 'antd'
const { Option } = Select
const normFile = (e) => {
  console.log('Upload event:', e)

  if (Array.isArray(e)) {
    return e
  }

  return e && e.fileList
}
function JoinUs(props) {
  const [form] = Form.useForm()
  const [upload, setUpload] = useState(null)
  const [title, setTitle] = useState(null)
  const [source, setSource] = useState(null)

  const onGenderChange = (value) => {}
  useEffect(() => {
    // console.log(props, 'props')
    setTitle(props?.location?.state?.title || null)
    setSource(props?.location?.state?.id || null)
  }, [props.location])
  const uploadProps = {
    name: 'file',
    // action: 'https://intranet-web.dalligent.com/customer/uploadFile',
    action: 'https://www.dalligent.com/api/customer/uploadFile',
    maxCount: 1,
    // headers: {
    //   authorization: 'authorization-text',
    //   'X-Requested-With':null,
    //   'a': '1'
    // },
    valuePropName: 'fileList',
    onChange(info) {
      setUpload('')
      console.log(info, 'change upload', info.file.status === 'done')
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        console.log(info, '上传成功')
        setUpload(info.file.response.body.fileName)
        message.success(`file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }
  const onFinish = (values) => {
    console.log(form.getFieldsValue(), 'form')
    console.log(save, 'save接口')
    const data = {
      ...values,
      upload,
      source,
    }
    console.log(data, 'data')

    save(data)
      .then((res) => {
        console.log(res)
        message.success('投递成功');
        form.resetFields();
      })
      .catch((err) => {
        message.error('投递失败');
      })
  }
  return (
    <div className="jion-us-container">
      <div className="banner">
        <img src={banner} alt="" className="pc" />
        <img src={bannerH5} alt="" className="h5" />
        <div className="title-warp">
          <p className="title">研发岗位 火热招聘中</p>
          <p className="tips">R&D positions are hotly recruiting</p>
        </div>
      </div>
      <div className="form-warp">
        <p className="title">
          您正在投递<span>{title}</span>职位
        </p>
        <Form
          form={form}
          name="control-hooks"
          layout="vertical"
          onFinish={onFinish}
          className="from"
        >
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                name="userName"
                label="姓名"
                rules={[
                  {
                    required: true,
                    message: '请输入姓名',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="phone"
                label="手机号码"
                rules={[
                  {
                    required: true,
                    message: '请输入手机号码',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                name="email"
                label="邮箱"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: '请输入邮箱',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="eduLevel"
                label="最高学历"
                rules={[
                  {
                    required: true,
                    message: '请选择最高学历',
                  },
                ]}
              >
                <Select
                  placeholder="Select a option and change input text above"
                  onChange={onGenderChange}
                  allowClear
                >
                  <Option value="本科">本科</Option>
                  <Option value="硕士">硕士</Option>
                  <Option value="博士">博士</Option>
                  <Option value="高中">高中</Option>
                  <Option value="大专">大专</Option>
                  <Option value="中专">中专</Option>
                  <Option value="MBA">MBA</Option>
                  <Option value="其他">其他</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="uploadList"
                label="上传简历"
                valuePropName="response"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    // message: '请上传简历',
                    validator: async (_, file) => {
                        console.log(file, 'upload validator', upload)
                        if(upload && file) {
                          return Promise.resolve()
                        } else {
                         return Promise.reject(new Error('请上传简历'))
                        }
                    },
                  },
                ]}
              >
                <Upload {...uploadProps}>
                  <Button className="upload">上传简历</Button>
                  <p className="upload-tips">
                    支持pdf、doc、ppt、docx、pptx、wps、html、jpg、jpeg、png、rtf、txt等简历格式
                  </p>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          {/* <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.gender !== currentValues.gender
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('gender') === 'other' ? (
                <Form.Item
                  name="customizeGender"
                  label="Customize Gender"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your customizeGender!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : null
            }
          </Form.Item> */}
          <Form.Item className="button-submit">
            <Button type="primary" htmlType="submit" className="submitBtn">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default JoinUs
