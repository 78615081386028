// 返回按钮
import './index.scss'
import React from 'react'
import { useHistory} from 'react-router-dom'
// import { Link } from 'react-router-dom'

function Back(props) {
  const route = useHistory()
  const jump = () => {
    route.go(-1)
  }
    return (
      <div className='components-back'>
        <div className='button' onClick={jump}>
          <img src={require('../../static/go_back.png')} alt=""/>
          <span>返回</span>
        </div>
      </div>
    )
}

export default Back
