import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper";
import "swiper/css/navigation";
import './index.scss'
import union from './img/union.png'
import quanr from './img/quanr.png'
import quanlb from './img/quanlb.png'
import HomeTitle from '../../../components/HomeTitle'

function Technology() {
  let route = useHistory()
  const jumpTo = (params) => {
    route.push({pathname: '/productdetail', search: `?id=${params}`})
  }
  return (
    <>
      <div className='tech-container'>
        <img className='img1' src={union} alt=""/>
        <img className='img2' src={quanr} alt=""/>
        <img className='img3' src={quanlb} alt=""/>
        <HomeTitle titleTips='TECHNOLOGY' title='技术成果' />
        <div className="content-container">
          <div className='content-left'>
            <p className='title'>以技术为驱动引擎，探索灵活用工市场的下一代形态</p>
            <p className='content'>
              作为一家高科技互联网企业，数智方科技专注数字科技创新应用与实践，以云原生PaaS、
              大数据、灵活的业务组件等为核心技术，为企业提供数智化解决方案和定制化技术服务，
              推动企业营收增长
            </p>
          </div>
          <div className='content-right'>
            <div className='top' onClick={() => jumpTo(1)}>
              <h1>云原生应用平台</h1>
              <p>KCAP-云原生应用平台（Kupu CloudNative Application Platform，KCAP）为企业提供服务化、高弹性、可观测性、过程自动化的PaaS平台，帮助企业提升云原生应用生命周期管理的效率，同时支持容器管理、CI/CD、微服务、ServiceMesh、API网关等全栈云原生能力。</p>
              <i></i>
            </div>
            <div className='bottom'>
              <div className="item" onClick={() => jumpTo(2)}>
                <h1>云原生数据平台</h1>
                <p>云原生数据平台（KupuData™）提供一站式可视化数据建设和数据开发平台，集容器云、数据汇聚、数据规划、数据开发、数据智能、数据资产、数据服务等功能于一体。解决中大企业面临的数据孤岛、数据维护困难、数据价值利用低的问题。帮助企业让数据成为资产</p>
                <i></i>
              </div>
              <div className="item" onClick={() => jumpTo(3)}>
                <h1>自研数据库</h1>
                <p>数智方自研数据库为企业打造 更可靠、安全、高性能、便捷 运维的数据库服务，
                  满足大 型企业对分布式事务一致 性、数据安全、高可用容灾 等多方面的要求，
                  保障云上 数据库的安全，实现用户的 数据库无缝迁云。</p>
               <i></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*mobile*/}
      <div className='tech-container-mobile'>
        <h1 className="title">技术成果</h1>
        <p className="sub-title">以技术为驱动引擎，探索社交和融融融合的下一代形态。</p>
        <p className="content">基于优质内容，以技术为驱动引擎，探索社交和融融融合的下一代形态。基于优质内容，以技术为驱动引擎，探索社交和融融融合的下一代形态。基于优质内容，以技术为驱动引擎，探索社交和融融融合的下一代形态。基于优质内容，以技术为驱动引擎，探索社交和融融融合的下一代形态。</p>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={16}
          navigation={true}
          mousewheel={false}
          modules={[Navigation, Mousewheel]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className='slide' onClick={() => jumpTo(1)}>
              <h1 className='title'>云原生应用平台</h1>
              <p className='content'>KCAP-云原生应用平台（Kupu CloudNative Application Platform，KCAP）为企业提供服务化、高弹性、可观测性、过程自动化的PaaS平台，帮助企业提升云原生应用生命周期管理的效率，同时支持容器管理、CI/CD、微服务、ServiceMesh、API网关等全栈云原生能力。</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='slide' onClick={() => jumpTo(2)}>
              <h1 className='title'>云原生数据平台</h1>
              <p className='content'>云原生数据平台（KupuData™）提供一站式可视化数据建设和数据开发平台，集容器云、数据汇聚、数据规划、数据开发、数据智能、数据资产、数据服务等功能于一体。解决中大企业面临的数据孤岛、数据维护困难、数据价值利用低的问题。帮助企业让数据成为资产</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='slide' onClick={() => jumpTo(3)}>
              <h1 className='title'>自研数据库</h1>
              <p className='content'>数智方自研数据库为企业打造 更可靠、安全、高性能、便捷 运维的数据库服务，
                满足大 型企业对分布式事务一致 性、数据安全、高可用容灾 等多方面的要求，
                保障云上 数据库的安全，实现用户的 数据库无缝迁云。</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  )
}

export default Technology
