// 返回按钮
import './index.scss'

function HeadImg(props) {
  // console.log(props, 123)
  return (
    <div className='components-head-img'>
      <img src={props.src} alt="" className='head-img-pc' />
      <img src={props.mobile} alt="" className='head-img-mobile'/>
      <div className='head-img-text'>
        <div className='head-img-title'>{ props.title }</div>
        <div className={ props.line ? 'head-img-titleTranslate line-light' : 'head-img-titleTranslate line-light-short' }>{ props.titleTranslate }</div>
      </div>
    </div>
  )
}

export default HeadImg
