import './index.scss'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Collapse, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import hot from '../../static/hot.png'
const { Panel } = Collapse
const tableTitle = [
  {
    name: '职位名称',
  },
  {
    name: '职位类别',
  },
  {
    name: '工作地点',
  },
  {
    name: '薪资待遇',
  },
  {
    name: '工作经验',
  },
  {
    name: '学历',
  },
]
const tableData = [
  {
    classfilyFmt: '产品类',
    classfily: 2, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '产品经理（海外项目）',
    experience: '3-5年',
    school: '-',
    salary: '20-35K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 进行印尼互联网市场洞察，结合印尼当地合作伙伴业务现状，进行新产品的挖掘与定义；',
      '2. 负责新业务产品设计，产出PRD需求和交互文档；',
      '3. 协调并推动运营、UED、技术、财税法等部门成员，确保产品项目进度；',
      '4. 支撑相关业务的运营需求，与内外部合作伙伴一起达成商业目标；',
      '5. 按照增长阶段性目标，负责产品改进，组织协调研发、运营等团队进行有效的增长策略落地;',
      '6. 对数据敏感，有强烈好奇心，工作的主要成就感来自于产品成功和数据指标的提升，擅长通过数据分析来推动决策;',
      '7. 通过线上调研、线下走访，发现当前用户结构的问题与优化空间，找到增长点，输出针对性产品与运营策略;',
      '8 .精通增长理论，对于用户增长的趋势有准确判断，同时可以找出用户增长的关键因子;',
    ],
    JobSpe: [
      // 任职要求
      '1. 能够用英文进行流利的沟通；',
      '2. 5年以上互联网产品经理工作经验，独立承担过业务流程与逻辑较复杂的产品或开放平台设计和落地实现，并有成功经验；',
      '3. 有较完善的产品设计方法论，能够提供高质量的产品原型、流程图、线框图等清晰的表达设计方案；',
      '4. 强目标导向，较强的执行力，关注结果和时间点；清晰的逻辑思维，良好的团队协作、创新意识和抗压能力；',
      '5. 对用户有深刻洞察，有过成功的用户运营相关经验;',
      '6. 对数据敏感，有较强的数据分析能力，能从数据中发现并解决问题。',
    ],
    hot: true,
  },
  {
    classfilyFmt: '产品类',
    classfily: 2, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '高级产品经理',
    experience: '5-10年',
    school: '本科',
    salary: '20-35K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 进行印尼互联网市场洞察，结合印尼当地合作伙伴业务现状，进行新产品的挖掘与定义；',
      '2. 负责新业务产品设计，产出PRD需求和交互文档；',
      '3. 协调并推动运营、UED、技术、财税法等部门成员，确保产品项目进度；',
      '4. 支撑相关业务的运营需求，与内外部合作伙伴一起达成商业目标。',
      '5. 按照增长阶段性目标，负责产品改进，组织协调研发、运营等团队进行有效的增长策略落地;',
      '6. 对数据敏感，有强烈好奇心，工作的主要成就感来自于产品成功和数据指标的提升;擅长通过数据分析来推动决策;',
      '7. 通过线上调研、线下走访，发现当前用户结构的问题与优化空间，找到增长点，输出针对性产品与运营策略;',
      '8. 精通增长理论，对于用户增长的趋势有准确判断，同时可以找出用户增长的关键因子;',
      '9. 对用户心理有深入研究，对用户AB Test有大量的实践经历，对通过活动、宣传、短信/PUSH营销等运营手段有成功案例。',
    ],
    JobSpe: [
      // 任职要求
      '1. 能够用英文进行流利的沟通',
      '2. 5年以上互联网产品经理工作经验，独立承担过业务流程与逻辑较复杂的产品或开放平台设计和落地实现，并有成功经验；',
      '3. 有较完善的产品设计方法论，能够提供高质量的产品原型、流程图、线框图等清晰的表达设计方案；',
      '4. 强目标导向，较强的执行力，关注结果和时间点；清晰的逻辑思维，良好的团队协作、创新意识和抗压能力；',
      '5. 对用户有深刻洞察，有过成功的用户运营相关经验;',
      '6. 对数据敏感，有较强的数据分析能力，能从数据中发现并解决问题。',
    ],
    hot: false,
  },
  {
    classfilyFmt: '产品类',
    classfily: 2, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '总裁助理（应届生）',
    experience: '-',
    school: '-',
    salary: '8-13K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 为公司重点培育对象，要求可撰写英文邮件、公文、报告等各种文件和翻译工作，包括总裁签发文件管理，文件信息上传下达工作；',
      '2. 参与涉外商务及外联的沟通工作；',
      '3. 参与到公司的经营管理工作；',
      '4. 具有良好的沟通协调及公关能力，能够组织、策划、安排相关会务，并能准确地进行会议记录和整理；',
      '5. 负责总经理处理日常事务.',
    ],
    JobSpe: [
      // 任职要求
      '1. 大学本科以上学历，英语六级以上，英语等相关专业毕业优先；',
      '2. 具有良好的文案撰写能力及英文听、说、读、写能力；',
      '3. 在校做过学生会或社会团体负责人，有很好的外联能力，学习能力强',
      '4. 具有良好的商务礼仪素质，形象端庄得体，综合素质好，语言表达能力强；',
    ],
    hot: false,
  },
  {
    classfilyFmt: '产品类',
    classfily: 2, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '内容运营高级经理',
    experience: '3-5年',
    school: '本科',
    salary: '20-30K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 主持品牌官方账号的内容运营工作，包括但不限于微信公众号、双微、官网等社媒平台，制定内容运营策略与内容生态方向的规划，包括公司品牌、外宣、推广，产品营销策划；',
      '2. 对于企业微信、百度推广维护及运作，产品外宣等内容的撰写；',
      '3. 通过热点或时间节点打造相应的内容栏目，培养用户的品牌认知；',
      '4. 能准确了解用户画像和用户需求，完整内容的推荐和分发；',
      '5. 市场新闻资讯等信息的获取、整理和输出；',
      '6. 负责公司用户运营素材攥写等日常文案撰写及运营工作；',
      '7. 可独立完成相应活动策划、文案撰写、运营，整体活动执行和把控；',
      '8. 领导安排的其他任务。',
    ],
    JobSpe: [
      // 任职要求
      '1. 本科及以上学历，5年以上内容运营工作经验，熟悉互联网营销方法，有较强的线上营销策划及执行能力，SaaS或to B行业的市场文案、推广经验者优先',
      '2. 能独立写作硬广和软文，有很好的创意和文案能力，善于捕捉产品亮点，创作过有传播量的作品.有百度、微信、网络推广经验者优先；',
      '3. 有较强的文案撰写和出PPT的能力；',
      '4. 高度的工作热情，良好的团队合作精神勤奋踏实、认真负责，有团队合作精神及坚韧的毅力。',
      '5. 较强的学习能力，推动执行能力',
    ],
    hot: false,
  },
  {
    classfilyFmt: '产品类',
    classfily: 2, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '海外支付产品经理',
    experience: '-',
    school: '-',
    salary: '面议',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责海外支付平台核心支付系统和金融支付中心产品设计工作；',
      '2. 负责海内外金融渠道对接，以及金融支付中心建设，为支付产品提供路由服务；',
      '3. 负责与当地业务线对接，根据业务线诉求提供收银台等支付产品，并根据业务场景和需求不断对支付产品进行迭代升级；',
      '4. 负责对应系统产品设计、评审、项目开发进度跟踪和运营数据分析，不断改进和提升产品体验；',
      '5. 负责支付系统的金融风控产品设计以及后期迭代；',
      '6. 向相关团队提供系统培训和操作手册。',
    ],
    JobSpe: [
      // 任职要求
      '1. 本科以上学历，具有2年以上金融或者第三方支付公司产品设计经验',
      '2. 对收银台、核心支付系统或金融业务流程了解，并主导或参与过产品设计；',
      '3. 对支付系统中涉及到的信息流和资金流有清晰的认识，熟悉整体产品架构；',
      '4. 有优秀的逻辑分析能力、业务能力、学习能力、沟通能力和项目管理能力；',
      '5. 能够自我驱动，且有较强的抗压能力',
    ],
    hot: false,
  },
  {
    classfilyFmt: '设计类',
    classfily: 3, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '高级UI设计师',
    experience: '3-5年',
    school: '本科',
    salary: '20-30K',
    address: '北京',
    JobRes: [
      // 工作职责
      '(加分项:英文沟通能力)',
      '1. 负责海外招聘产品移动端的UI设计工作，准确传达设计理念；',
      '2. 理解产品特性，参与产品构思，并能够独立跟进项目、与上下游的产品及研发团队沟通协作，保证最终上线效果；',
      '3. 制定所负责产品线的设计规范，保证体验一致性；',
      '4. 参与团队品牌相关的创意构思、设计执行工作。',
    ],
    JobSpe: [
      // 任职要求
      '1. 本科及以上学历，艺术、设计或其他相关专业背景，3年及以上移动端产品UI设计经验。',
      '2. 精通Sketch、Photoshop、Illustrator、AE、C4D等设计工具，可以通过3D和动效手段营造界面氛围。',
      '3. 具备出色的设计表达能力，善于沟通并将有效的设计方案推进落地；',
      '4. 具备产品设计、交互设计、用户研究等横向知识；',
      '5. 具备良好合作态度及团队精神，能承受高强度的工作压力，具备良好的跨团队沟通和协作能力;',
    ],
    hot: true,
  },
  {
    classfilyFmt: '设计类',
    classfily: 3, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: 'UI设计师',
    experience: '3-5年',
    school: '本科',
    salary: '15-20K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责海外招聘产品移动端的UI设计工作，准确传达设计理念；',
      '2. 理解产品特性，参与产品构思，并能够独立跟进项目、与上下游的产品及研发团队沟通协作，保证最终上线效果；',
      '3. 制定所负责产品线的设计规范，保证体验一致性；',
      '4. 参与团队品牌相关的创意构思、设计执行工作。',
    ],
    JobSpe: [
      // 任职要求
      '1. 本科及以上学历，艺术、设计或其他相关专业背景，3年及以上移动端产品UI设计经验。',
      '2. 精通Sketch、Photoshop、Illustrator、AE、C4D等设计工具，可以通过3D和动效手段营造界面氛围。',
      '3. 具备出色的设计表达能力，善于沟通并将有效的设计方案推进落地；',
      '4. 具备产品设计、交互设计、用户研究等横向知识；',
      '5. 具备良好合作态度及团队精神，能承受高强度的工作压力，具备良好的跨团队沟通和协作能力;',
    ],
    hot: false,
  },
  {
    classfilyFmt: '设计类',
    classfily: 3, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '品牌视觉设计师',
    experience: '3-5年',
    school: '-',
    salary: '15-25K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 品牌设计、品牌识别设计，线上线上包装设计；',
      '2. 负责日常运营活动及功能维护提供美术支持，包含本品牌所有调性，如广告图创新、活动页、详情页、入口图等的设计与策划工作，视觉效果须达到公司品牌标准；',
      '3. 负责公司形象设计，品牌宣传设计，公司对外宣传活动设计；',
      '4. 创意设计，涉及VI、画册、海报、包装等所有设计与文案工作的统筹安排以及参与；',
      '5. 负责图片制作、后期处理及上传，整体把握品牌视觉效果；',
      '6. 品牌线上、线下活动物料设计；',
      '7. 具备企划思路，能分析市场和研究竞争对手，并指导制定企划方案。',
    ],
    JobSpe: [
      // 任职要求
      '1. 本科及以上学历，美工艺术商业设计及相关专业；',
      '2. 有五年以上VI设计商业设计相关工作优先；',
      '3. 熟练使用MacOS、PS、AdobeInDesign等设计软件；',
      '4. 有扎实的美术功底、了解流行趋势、构图与色彩表达、有良好的创意思维和表达方式；',
      '5. 有较强的团队合作精神、有责任心、注意细节。',
    ],
    hot: false,
  },
  {
    classfilyFmt: '职能类',
    classfily: 4, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '人力行政专员',
    experience: '-',
    school: '大专',
    salary: '面议',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 协助部门经理拟定公司的各项规章制度及工作流程;',
      '2. 协助上级为其它部门提供及时有效的行政服务;',
      '3. 协助上级执行公司的培训计划以及有效的后勤保障工作;',
      '4. 协助组织、开展企业文化活动;',
      '5. 建立固定资产台账并按时清点；',
      '6. 负责前台接待事宜；',
      '7. 负责员工考勤工作;',
      '8. 完成领导交办的其它工作.',
    ],
    JobSpe: [
      // 任职要求
      '1. 大学专科及以上学历，有1-3年人力行政经验，人力资源管理相关专业优先;',
      '2. 工作积极乐观、有责任心、反应快、抗压力强，愿意学习。',
    ],
    hot: true,
  },
  {
    classfilyFmt: '职能类',
    classfily: 4, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '总裁助理（应届生）',
    experience: '-',
    school: '本科',
    salary: '8-13K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 为公司重点培育对象，要求可撰写英文邮件、公文、报告等各种文件和翻译工作，包括总裁签发文件管理，文件信息上传下达工作；',
      '2. 参与涉外商务及外联的沟通工作；',
      '3. 参与到公司的经营管理工作；',
      '4. 具有良好的沟通协调及公关能力，能够组织、策划、安排相关会务，并能准确地进行会议记录和整理；',
      '5. 负责总经理处理日常事务.',
    ],
    JobSpe: [
      // 任职要求
      '1. 大学本科以上学历，英语六级以上，英语等相关专业毕业优先；',
      '2. 具有良好的文案撰写能力及英文听、说、读、写能力；',
      '3. 在校做过学生会或社会团体负责人，有很好的外联能力，学习能力强',
      '4. 具有良好的商务礼仪素质，形象端庄得体，综合素质好，语言表达能力强；',
    ],
    hot: false,
  },
  {
    classfilyFmt: '职能类',
    classfily: 4, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '会计',
    experience: '-',
    school: '本科',
    salary: '15-30K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 在公司董事会和总经理领导下，总管公司会计、报表、预算工作；',
      '2. 负责制订公司利润计划、财务规划、开发投资计划、开支及成本预算；',
      '3. 建立健全公司内部核算的组织，以及财务管理的规章制度；',
      '4. 组织公司有关部门开展经济活动分析，组织编制公司财务计划、成本计划、努力降低成本、增收节支、提高效益；',
      '5. 负责本单位会计机构的设置，并就会计人员的配备，会计职务的设置和聘任提出方案，组织会计人员培训，(规章制度 )支持会计人员依法行使职权；',
      '6. 协助领导对公司的经营作出决策，参与各种经济合同、经济计划和开发计划的研究、审查；及时向董事会、总经理提出合理化建议；',
      '7. 监督本公司贯彻执行国家的各项财税政策、法令和制度。',
    ],
    JobSpe: [
      // 任职要求
      '1.五年以上财务管理及团队管理经验，熟悉年度决算及所得税汇算清缴工作；',
      '2.具有良好的职业道德，具备一定的组织能力，具备互联网或电商行业工作经验者优先；',
      '3.具有大学本科以上学历，有会计师职称或注册会计师资格；',
      '4.熟悉财务管理工作，精通公司会计核算，熟知国家的财经法律、法规、规章制度、方针和政策；',
      '5.能熟练操作计算机。',
    ],
    hot: false,
  },
  {
    classfilyFmt: '职能类',
    classfily: 4, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '行政',
    experience: '-',
    school: '本科',
    salary: '6-8K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责行政部的复印，文件收发等行政工作；',
      '2. 负责公司行政文件，资料档案的管理：',
      '3. 负责接待来访人员；',
      '4. 完成领导交代的其他工作。',
    ],
    JobSpe: [
      // 任职要求
      '1. 形象气质佳，沟通能力强；',
      '2. 工作严谨，积极主动，抗压力强；',
      '3. 熟悉办公软件的操作。',
    ],
    hot: false,
  },
  {
    classfilyFmt: '职能类',
    classfily: 4, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '人力资源实习生',
    experience: '-',
    school: '大专',
    salary: '面议',
    address: '北京',
    JobRes: [
      // 工作职责
      '1、招聘基本工作，发布招聘岗位，筛选简历，沟通候选人，支持招聘工作；',
      '2、月度五险一金的操作和办理；',
      '3、考勤核算、花名册等基础数据的更新和维护；',
      '4、员工活动、培训的前期准备工作；',
      '5、领导交代的其他工作。',
    ],
    JobSpe: [
      // 任职要求
      '1、大专及以上学历，人力资源相关专业优先；',
      '2、主动、乐观，具备好的沟通与协作能力，服从上级安排，能承受压；',
      '3、每周坐班周一至周五，双休。',
    ],
    hot: false,
  },
  {
    classfilyFmt: '职能类',
    classfily: 4, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: 'HRBP',
    experience: '5-10年',
    school: '大专',
    salary: '10-15K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 根据公司战略和业务实际需要，拟定招聘计划，同时优化招聘流程及招聘制度，进一步完善招聘体系，完成全年人力资源规划及招聘需求整理；',
      '2. 负责人才招聘，推进人才挖掘、寻访、甄选、评估、内部面试安排以及面试录用等，能够代表本公司与候选人深入沟通；',
      '3. 深入了解业务团队人员发展状况，了解组织健康，协同业务部门进行团队管理，评估并明确组织与人才发展。包括绩效和培训等各项组织建设工作，提高组织运转效率，持续提升团队能力均线；',
      '4. 协助管理层进行人才管理、团队发展、组织氛围建设等，确保公司文化在所属业务领域的落地，推动公司人力资源相关项目在业务部门的落地；',
      '5. 完善机制并落地：通过建立或完善机制，协助业务部门形成有力的人力资源管理保障，并推进落地，包括招聘、薪酬、培训、绩效、员工关系等人力资源工作；',
      '6. 绩效与组织发展：根据业务部门人力资源策略和现状，提出绩效管理及人才组织发展计划，促进人才和组织的良性发展；',
      '7. 培训及文化建设：通过培训、研讨及团建等活动，促进内部文化建设，形成良好的学习型组织；',
      '8. 员工关系：通过谈话及辅导，了解员工思想动态，促进员工发展及管理改善；',
      '9. 领导交办的其他工作内容。',
    ],
    JobSpe: [
      // 任职要求
      '1. 专科及以上学历、3年以上HRBP工作经验，具有招聘专业知识和经验，熟悉招聘流程和招聘渠道；',
      '2. 互联网相关行业背景，对业务有较深入的理解，有猎头经验者优先；',
      '3. 逻辑思维能力强，善于沟通，具有一定承压能力优秀的个人综合素质，有较强的事业心与成就动机；',
      '4. 人际理解力、沟通协调和主动推动能力强，善于整合资源驱动目标达成，出色的多任务、多角色平衡能力，具有分析和识别人才能力，冷静、客观分析和判断能力；',
      '5. 能适应快速变化的竞争环境，有较强的抗压能力并能以开放的心态拥抱变化；',
      '6. 较强的责任心和抗压能力，善于沟通，乐于合作,服从上级领导的工作安排。',
    ],
    hot: false,
  },
  {
    classfilyFmt: '职能类',
    classfily: 4, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '人力资源专员',
    experience: '1-3年',
    school: '大专',
    salary: '6-8K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 协助定制公司人事规章制度及流程、表格。',
      '2. 建立员工档案库，负责员工人事档案及部门档案的管理，各类资料的登记、分类、及时归档并更新相关资料；',
      '3. 负责员工劳动合同的签订及续签，处理与劳动合同相关的工作；',
      '4. 负责员工的入、离、转、调手续的办理；',
      '5. 负责员工五险一金业务的办理，月度考勤的制作；',
      '6. 协助部门人力资源规划，组织发展、人事运营等工作；',
      '7. 协助组织人员招聘，通过多渠道为公司寻求合适的人才；',
      '8. 协助公司集体活动的组织与安排、节假日慰问等工作；',
      '9. 完成领导交代的其他工作。',
    ],
    JobSpe: [
      // 任职要求
      '1. 1-3年同岗位相关经验，有互联网技术工作经验优先；',
      '2. 统招专科及以上学历，具备HR专业知识；；',
      '3. 工作细致、认真、负责，有良好的执行力和职业素养；',
      '4. 具有良好的书面、偶头表达能力，具有亲和力和服务意识，沟通领悟能力强；',
      '5. 熟练使用办公软件，了解国家劳动法规法策；',
      '6. 有强烈的责任感和敬业精神，做事严谨，能承受一定的工作压力。',
    ],
    hot: false,
  },
  {
    classfilyFmt: '职能类',
    classfily: 4, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '招聘主管',
    experience: '3-5年',
    school: '大专',
    salary: '10-12K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 根据公司战略和业务实际需要，拟定招聘计划，同时优化招聘流程及招聘制度，进一步完善招聘体系，完成全年人力资源规划及招聘需求整理；',
      '2. 负责人才招聘，推进人才挖掘、寻访、甄选、评估、内部面试安排、录用、背调等，能够代表本公司与候选人深入沟通；',
      '3. 开发优质的校招资源，独立进行校园招聘计划；',
      '4. 培训及文化建设：通过培训、研讨及团建等活动，促进内部文化建设，形成良好的学习型组织；',
      '5. 随时关注员工的思想动态，员工关系的维护，进行离职面谈等；',
      '6. 领导交办的其他工作内容。',
    ],
    JobSpe: [
      // 任职要求
      '1. 专科及以上学历、3-5年以上人力资源工作经验，具有招聘专业知识和经验，熟悉招聘流程和招聘渠道；',
      '2. 互联网相关行业背景，对业务有较深入的理解，有猎头经验者优先；',
      '3. 能进行岗位分析、会人才画像的编写，了解同行业人才分布，撰写人才报告及招聘数据分析；',
      '4. 逻辑思维能力强，善于沟通，具有一定承压能力优秀的个人综合素质，有较强的事业心与成就动机；',
      '5. 较强的责任心和抗压能力，善于沟通，乐于合作,服从上级领导的工作安排。',
    ],
    hot: false,
  },
  {
    classfilyFmt: '职能类',
    classfily: 4, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '员工关系',
    experience: '3-5年',
    school: '-',
    salary: '10-12K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 根据公司发展规划，制定和完善员工关系管理体系；',
      '2. 建立员工档案库，负责员工人事档案及部门档案的管理，各类资料的登记、分类、及时归档并更新相关资料；',
      '3. 负责员工劳动合同的签订及续签，处理与劳动合同相关的工作；',
      '4. 负责员工的入、离、转、调手续、员工福利的办理；',
      '5. 与员工进行有效的沟通交流，建立企业内部沟通渠道；',
      '6. 策划企业文化活动、丰富员工生活，提高员工关系融洽度、满意度；',
      '7. 熟练操作办公软件，有良好的文字功底，能解决突发的员工关系事件；',
      '8. 领导交办的其他工作。',
    ],
    JobSpe: [
      // 任职要求
      '1. 3-5年人力资源相关工作经验，互联网工作经验优先；',
      '2. 具备人力资源专业知识，熟悉国家劳动法律法规；',
      '3. 具备良好的统计分析能力，良好的沟通能力；',
      '4. 工作认真、负责，有良好的抗压性，能够接受事物变化，有良好的执行力和服从性。',
    ],
    hot: false,
  },
  {
    classfilyFmt: '研发类',
    classfily: 5, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: 'Java高级工程师',
    experience: '5-10年',
    school: '本科',
    salary: '25-30K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 公司电子商务平台开发和维护',
      '2. 与产品、测试、运维及其运营团队沟通，负责电子商务平台场景的研发',
      '3. 产品后期运维及bug修复',
      '4. 带领小组进行设计开发',
    ],
    JobSpe: [
      // 任职要求
      '1. 大学本科以上学历，计算机科学、软件工程专业优先，985/211 高校背景优先',
      '2. 5年以上Java研发经验',
      '3. 熟悉面向对象及面向函数的开发',
      '4. 熟悉Spring相关框架，例如SpringBoot，Spring Security，Spring Data，Spring Cloud等',
      '5. 至少有一种容器开发的经验，例如Docker等',
      '6. 熟悉敏捷开发，熟练使用相关工具，有带队经验可以加分',
      '7. 善于主动思考，提出自己的意见',
      '8. 了解Hadoop、Spark、OLAP等大数据技术可以加分',
      '9. 有电子商务平台相关研发经验者可以加分',
    ],
    hot: true,
  },
  {
    classfilyFmt: '研发类',
    classfily: 5, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '前端开发工程师（小程序方向）（研发部）',
    experience: '-',
    school: '本科',
    salary: '10-15K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 主要负责电商方向小程序前端开发；',
      '2. 配合UI设计师实现页面渲染，配合后端工程师一起做数据联调，参与产品的需求分析，并制定技术方案和接口设计；',
      '3. 不断优化小程序交互体验，不断优化体验性能；',
      '4. 配合后端参与小程序、web前端的优化和维护工作，快速响应用户反馈，负责线上用户遇到问题的排查和解决',
    ],
    JobSpe: [
      // 任职要求
      '1. 两年以上小程序开发经验，能独立负责简单小程序项目前端的研发工作；',
      '2. 熟悉css/css3、html5，熟悉原生微信小程序开发。公众号开发经验加分；',
      '3. 熟悉常见小程序开发框架，比如MpVue, WEPY, Taro, 有丰富的框架使用经验，并有过小程序组件开发案例；',
      '4. 有React，Vue，Angular，Ant design等框架的Web开发经验的加分考虑；',
      '5. 熟悉敏捷开发，有较强的主动学习能力，工作激情和抗压能力，乐观向上，沟通主动；',
      '6. 熟悉git， 版本控制， 有多人同时开发项目的经验；',
      '7. 优先考虑参与过github开源项目， 熟悉git flow或相关的经历；',
      '8. 优先考虑有用户行为分析和打点开发等经验，优先考虑有小程序AB测试经验，优先考虑有小程序自动化测试、整合和部署的经验。',
    ],
    hot: true,
  },
  {
    classfilyFmt: '研发类',
    classfily: 5, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '测试工程师',
    experience: '-',
    school: '-',
    salary: '8-12K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责Web端、Android端、IOS端的产品功能、性能、集成测试，可搭建测试环境，',
      '2. 根据产品需求说明和设计文档，编写评审测试用例，撰写测试报告，维护BugList；',
      '3. 管理日常测试迭代工作，不断优化测试用例，钻研测试方法；',
      '4. 准确、详实的描述Bug产生的过程、Bug的现象，准确地定位并跟踪问题； ',
      '5. 参与开发组、产品组、算法组三方讨论，制定明确的测试计划和测试任务，并可对产品提出改进型建议；',
      '6. 与产品经理和开发人员就项目要求、进度和问题进行沟通',
    ],
    JobSpe: [
      // 任职要求
      '1. 大专及以上学历，计算机相关专业，3年互联网方向的测试经验；',
      '2. 精通软件测试方法，能独立编写测试用例，执行测试及反馈测试结果；',
      '3. 精通Bug管理跟踪工具，能对软件开发中的Bug进行跟踪和统计分析；',
      '4. 熟悉Python、Java、Go中的任意一门语言； ',
      '5. 熟练使用测试工具，例如jmeter、Charles、fiddler等；',
      '6. 熟练使用mysql、oracle等数据库；',
      '7. 责任心强，拥有较强的沟通技巧及团队合作精神；',
      '8. 有Web、Android、IOS相关产品测试经验，独立带完整项目的经历者优先。',
    ],
    hot: true,
  },
  {
    classfilyFmt: '研发类',
    classfily: 5, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '前端（架构）',
    experience: '-',
    school: '-',
    salary: '12-20K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责公司前端的技术方案框架设计及核心模块、组件、框架的方案设计与开发;',
      '2. 负责公司开发和完善前端库，建立完整前端技术体系和基础库，建立架构与组织文档;',
      '3. 建立与管理项目架构的更新和维护，评审技术方案对架构的影响，并提出解决方案',
      '4. 优化和改进目前现有的前端技术体系，保证系统稳定，易扩展性。满足业务线的快速、稳定、高效的运作;',
      '5. 优化产品用户体验和前端性能问题',
      '6. 负责前端交互决策与产品策划;',
      '7. 关注前沿技术，并能实际运用到项目中。',
    ],
    JobSpe: [
      // 任职要求
      '1. 精通常见开源前端框架，理解源码，可对其扩展和优化，自己有开发公共基础库、框架优先;',
      '2. 精通各种web前端技术和标准，包括但不限于Javascript/ES6、HTML/HTML5、CSS/CSS3、Sass/less、Vue/React/Node，对表现与数据分离、web协议、web安全、web性能、web语义化等有深刻理解;',
      '3. 熟练使用web前端相关工具，包括但不限于webpack、gulp、git、svn;',
      '4. 有丰富的前端开发经验，精通各种组件实现原理和设计概念，不断提高组件复用性和前端研发效率;',
      '5. 能够合理利用设计模式和模块化的组织方式对代码进行架构;',
      '6. 具有良好的沟通能力和团队协作、项目管理能力，能够协同相关成员推进项目发展。',
      '7. 大学本科以上学历，5年前端开发经验以上，3年前端架构设计经验以上，参与过大型互联网系统前端开发优先。',
    ],
    hot: true,
  },
  {
    classfilyFmt: '研发类',
    classfily: 5, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '即时通讯开发工程师',
    experience: '5-10年',
    school: '本科',
    salary: '20-40K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责对产品中IM的业务进行需求分析，参与IM服务端的技术架构设计；',
      '2. 负责核心编码工作；制定IM产品开发计划，根据需求编写产品代码；',
      '3. 负责优化、改进和实现IM协议，提高服务端的承载能力，最终达到满足千万级在线用户，为用户提供可靠实时的即时通讯服务；',
      '4. 负责核心IM产品核心的二次开发和功能加强，并且能够完善的支持业务；',
    ],
    JobSpe: [
      // 任职要求
      '1. 熟悉开源商用的相关IM产品及二次开发，并且熟悉主流IM产品的技术架构。具备高性能，高并发，高稳定性系统开发和调优实际工作经验；熟悉分布式、负载均衡、消息系统、缓存技术、异步框架等领域；',
      '2. 熟悉IM通讯机制及常用数据传输协议（如XMPP，socket.io）及其他IM开发相关技术；',
      '3. 熟悉Openfire,Tigase,Prosody,Mosquitto,ejabberd,ActorMessenger等IM服务器的一种，或有自主研发IM服务器的经验；',
      '4. 有大型IM服务端开发经验或架构设计经验者优先；',
      '5. 计算机相关专业本科，6年以上工作经验。',
    ],
    hot: false,
  },
  {
    classfilyFmt: '研发类',
    classfily: 5, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '大数据架构师',
    experience: '5-10年',
    school: '本科',
    salary: '20-40K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责公司数据产品线的整体架构规划、设计和性能调优；',
      '2. 负责数据平台的模型设计与可视化设计工作；',
      '3. 参与产品前期的可行性分析、产品需求评审、需求分析、架构设计、技术决策以及详细设计；',
      '4. 协助指导工程师解决关键研发问题；',
      '5. 负责产品的技术选型，设计开发关键功能模块。',
    ],
    JobSpe: [
      // 任职要求
      '1. 本科及以上学历，具备5年以上架构师工作经验，3年以上大数据相关领域工作经验；',
      '2. 熟悉Hadoop、SparkSQL、Flink、TensorFlow、Pig、ELK、Kettle、SpagoBI、Hbase等大数据相关开源框架，熟悉时序数据库、分布式数据库相关技术优先考虑；',
      '3. 精通HDFS、Hive、 Flume、Kafaka、Zookeeper等分布式存储技术；',
      '4. 具备一定的算法设计与优化能力；熟练使用常见的概率统计、数据挖掘、机器学习算法，包括聚类、回归、关联规则、决策树、朴素贝叶斯等常用算法到适用场景。',
      '5. 有互联网数据仓库平台、推荐系统、用户画像经验优先;',
      '6. 有知识图谱算法、图数据库或机器学习开发经验优先',
      '7. 具备优秀的沟通技巧，能有效地在压力下工作；',
      '8. 学习能力强，喜欢钻研新技术，有团队观念，具备独立归纳问题，分析问题，解决问题能力。',
    ],
    hot: true,
  },
  {
    classfilyFmt: '研发类',
    classfily: 5, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: 'IOS工程师',
    experience: '5-10年',
    school: '本科',
    salary: '15-30K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责iOS客户端设计与开发；',
      '2. 负责公司项目代码开发与维护；',
      '3. 关注性能和代码质量，提升客户端用户体验；',
    ],
    JobSpe: [
      // 任职要求
      '1. 本科及以上学历，良好的编程风格，具备一定的数据结构算法基础；',
      '2. 具有丰富的 Objective-C/Swift 语言开发经验，有非常良好的 Swift 语法基础或者多个实际项目者优先；',
      '3. 深入理解iOS SDK，精通各种UI控件，能够实现复杂的界面交互；',
      '4. 了解 Flutter 开发，有过实际开发经验最好;',
      '5. 有强烈的责任心和团队精神，良好的沟通表达能力和逻辑思维能力；',
      '6. 有海外项目开发经验优先；',
    ],
    hot: true,
  },
  {
    classfilyFmt: '研发类',
    classfily: 5, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '安卓工程师',
    experience: '5-10年',
    school: '本科',
    salary: '15-30K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 参与公司移动产品的迭代开发工作，能高质量的完成产品需求技术方案设计和开发任务；',
      '2. 参与项目优化方案和技术难点的攻关工作，提升产品质量和性能；',
    ],
    JobSpe: [
      // 任职要求
      '1. 计算机相关专业本科及以上学历；',
      '2. 5年或以上Android开发经验，能独立完成Android软件的设计和开发工作；',
      '3. Android平台开发功底扎实，熟悉内存分析和性能调优的方法；',
      '4. 熟练使用JAVA或者C/C++语言，熟悉常用数据结构和算法，有良好的编程风格和文档习惯；',
      '5. 熟练使用AS开发工具， 熟练的数据结构、代码设计能力;',
      '6. 思路清晰，善于思考，对数据敏感，能独立分析和解决问题，对代码的质量和性能有追求；',
      '7. 做事积极主动，责任心强，抗压性强且具备良好的团队合作与沟通能力；',
      '8. 做过电商，招聘类项目；',
      '9. 有flutter，kotlin开发经验。',
    ],
    hot: true,
  },
  {
    classfilyFmt: '研发类',
    classfily: 5, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '交付实施工程师',
    experience: '-',
    school: '本科',
    salary: '15-25K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责公司对外输出项目云平台的技术支持，确保客户业务的正常运行；',
      '2. 负责对事件进行总结和分析，排除云产品、大数据、中间件等故障，推动相关团队解决，在实施过程中提供技术支持；',
      '3. 沉淀解决方案形成知识库，整理技术文档，同时赋能客户及合作伙伴，提升自处理能力；',
      '4. 和研发团队对接，推动订制化需求落地开发测试，并确保能够有效的提升架构优化，打造一流的平台化服务；',
    ],
    JobSpe: [
      // 任职要求
      '1. 本科或以上学历，计算机相关专业，5年以上工作经验；',
      '2. 对于产品、技术有深入了解，熟悉业务需求分析；',
      '3. 有开发经验优先，精通一门以上脚本语言(shell/perl/python等)，熟悉Java/C++等开发语言一种及以上；',
      '4. 独立完成项目实施方案及调试验收；',
      '6. 具有较强的分析问题和快速解决问题的能力，善于学习新技术；',
      '7. 具有很强的责任感和抗压能力，善于故障排查，有很好的技术敏感度和风险识别能力； ',
      '8. 良好的沟通、组织协调能力和客户服务意识；',
    ],
    hot: false,
  },
  {
    classfilyFmt: '管理类',
    classfily: 6, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '战略研发总监',
    experience: '-',
    school: '-',
    salary: '面议',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 对部门所涉及的产业尤其是互联网的产业环境、关键要素、市场供需及竞争信息进行动态的持续跟踪和分析；',
      '2. 主动开展各项研究项目以支撑战略发展部未来决策及战略规划；',
      '3. 参与项目管理相关工作，主动承担一定的产品设计及产品创新工作并有效推动落地；',
      '4. 公司重点项目的持续跟进；',
      '5. 完成领导交付的其他工作。',
    ],
    JobSpe: [
      // 任职要求
      '1. 30-35岁，5-10年及以上相关行业研究员、分析师相关工作经验, 985、211院校研究生或具备互联网及电商相关工作经验者优先；',
      '2. 有良好的沟通能力、文案功底良好、理解能力强、有技术背景；',
      '3. 具备一定的财务及金融能力，并可接受出差安排；',
      '4. 具备较强的分析能力，掌握成熟的研究方法论，能够独立制定研究计划及完成调研工作；',
      '5. 具备较强的创新思维能力及持之以恒的毅力，能够主动积累、思考及探索创新方向。',
      '6. 优秀的职业操守，坚韧不拔，具备高度的工作责任心和团队合作意识。',
      '7. 具有良好的文案撰写能力及英文听、说、读、写能力，参与涉外商务及外联工作的沟通；',
    ],
    hot: false,
  },
  {
    classfilyFmt: '管理类',
    classfily: 6, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '法务经理',
    experience: '-',
    school: '-',
    salary: '面议',
    address: '北京',
    JobRes: [
      // 工作职责
      '负责公司知识产权的工作，为公司经营活动提供法律支持与意见，提示与规避经营活动中的法律风险。具体工作包括但不限于以下内容:',
      '1. 业务合同（中英文）等法律文件的撰写与审核工作，制订合同模板；',
      '2. 参与商务谈判，提供法律咨询服务，出具解决方案；',
      '3. 商标、著作权管理、资质证照管理；',
      '4. 制定公司管理制度，参与经营活动法律风险评估，为公司决策提供法律支持；',
      '5. 诉讼或案件的应对与解决；',
      '6. 处理公司其他日常事务及完成上级领导交办的其他工作。',
    ],
    JobSpe: [
      // 任职要求
      '1. 本科及以上学历，法律专业背景，通过司法考试，要求有注册会计师证；',
      '2. 英语六级以上，听说读写精通；',
      '3. 5-8年以上法律或知识产权工作相关经验；',
      '4. 拥有较强的沟通能力与服务意识，能够主动且不断学习和提高，对工作充满热情与上进心；',
      '5. 具备良好的团体精神和协作能力，能够克服一定的工作压力；',
    ],
    hot: false,
  },
  {
    classfilyFmt: '管理类',
    classfily: 6, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '测试负责人',
    experience: '-',
    school: '-',
    salary: '15-18K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责Web端、Android端、IOS端的产品功能、性能、集成测试，可搭建测试环境，',
      '2. 根据产品需求说明和设计文档，编写评审测试用例，撰写测试报告，维护BugList；',
      '3. 准确、详实的描述Bug产生的过程、Bug的现象，准确地定位并跟踪问题；',
      '4. 管理日常测试迭代工作，不断优化测试用例，钻研测试方法；',
      '5. 参与开发组、产品组、算法组三方讨论，制定明确的测试计划和测试任务，并可对产品提出改进型建议；',
      '6. 与产品经理和开发人员就项目要求、进度和问题进行沟通',
    ],
    JobSpe: [
      // 任职要求
      '1. 大专及以上学历，计算机相关专业，5年互联网方向的测试经验；3年以上管理经验，有白盒测试经验优先，',
      '2. 精通软件测试方法，能独立编写测试用例，执行测试及反馈测试结果；',
      '3. 精通Bug管理跟踪工具，能对软件开发中的Bug进行跟踪和统计分析；',
      '4. 熟悉Python、Java、Go中的任意一门语言；',
      '5. 熟练使用测试工具，例如jmeter、Charles、fiddler等；',
      '6. 熟练使用mysql、oracle等数据库；',
      '7. 责任心强，拥有较强的沟通技巧及团队合作精神；',
      '8. 有Web、Android、IOS相关产品测试经验，独立带完整项目的经历者优先。',
    ],
    hot: false,
  },
  {
    classfilyFmt: '管理类',
    classfily: 6, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '海外项目经理',
    experience: '-',
    school: '-',
    salary: '面议',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责与海外（印尼）运营以及市场需求沟通，准确理解需求，提供可行的建议和解决方案；',
      '2. 负责制定项目目标及实施方案，保证开发的产品与目标相符，确保项目完成；',
      '3. 协调各部门之间的任务分配及进度，把控项目的整体进展；',
      '4. 负责协调沟通需求方、技术开发人员、技术运维人员等；',
      '5. 领导交办的其他工作'
    ],
    JobSpe: [
      // 任职要求
      '1. 全日制本科以上学历，计算机及相关专业，8年以上工作经验，5年以上软件研发项目经验，在过往担任项目经理角色经验中，不仅仅是承担纯粹的项目管理职责，也参与需求分析及系统开发等工作；',
      '2. 有较强的沟通协调能力、分析能力和解决问题的能力；',
      '3. 具备优秀的团队协作意识和高效的执行力；',
      '4. 具有良好的心理素质，能够承受较大的工作压力；',
      '5. 必备条件：英语口语可以作为日常工作沟通，读写能力优秀。',
      '6. 加分项：懂印尼语',
    ],
    hot: false,
  },
  {
    classfilyFmt: '管理类',
    classfily: 6, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '法务经理',
    experience: '-',
    school: '本科以上',
    salary: '15-20K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责公司知识产权的工作，为公司经营活动提供法律支持与意见，提示与规避经营活动中的法律风险。具体工作包括但不限于以下内容:',
      '2. 业务合同（中英文）等法律文件的撰写与审核工作，制订合同模板；',
      '3. 参与商务谈判，提供法律咨询服务，出具解决方案；',
      '4. 商标、著作权管理、资质证照管理；',
      '5. 公司诉讼或案件的应对与解决；',
      '6. 制定公司管理制度，参与经营活动法律风险评估，为公司决策提供法律支持；',
      '7. 处理公司其他日常事务及完成上级领导交办的其他工作。'
    ],
    JobSpe: [
      // 任职要求
      '1. 本科及以上学历，法律专业背景，通过司法考试，要求有注册会计师证；',
      '2. 英语六级以上，听说读写精通优先；',
      '3. 5-8年以上法律或知识产权工作相关经验；  ',
      '4. 拥有较强的沟通能力与服务意识，能够主动且不断学习和提高，对工作充满热情与上进心；',
      '5. 具备良好的团体精神和协作能力，能够克服一定的工作压力；',
      '6. 掌握商标、著作权、专利，以及互联网行业法律法规。'
    ],
    hot: false,
  },
  {
    classfilyFmt: '管理类',
    classfily: 6, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '财务主管',
    experience: '-',
    school: '本科',
    salary: '10-12K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责公司的会计核算及管理工作，保证公司会计核算工作的准确性和及时性;懂得财务预算分析，根据公司战略能够做出适当的财务预算;',
      '2. 审核日常的会计业务(包括记账凭证、审核报销及发票等)及时、准确地编制会计报表、报送各项统计资料;',
      '3. 能够独立完整搭建财务架构体系，完善健全财务各项制度。',
      '4. 负责协调财务与各部门之间的关系，主动解决工作中出现的问题，带领财务人员高效准确地做好财务工作;',
      '5. 负责审计、税务、投资人需求等报送资料的统筹管理、事项对接等；',
      '6. 完成上级领导交付的其他工作.'
    ],
    JobSpe: [
      // 任职要求
      '1. 会计、财务管理、审计等相关专业本科及以上学历，有过3-5年的会计工作经验；',
      '2. 扎实的专业功底，持有会计中级以上职称，CPA、CMA或ACCA优先，四大工作经验优先;',
      '3. 熟悉财务管理、预算管理、成本管理等流程，有财务系统建设搭建经验；',
      '4. 擅长财务分析，熟悉财务分析模型，熟练使用财务专用软件及办公软件;',
      '5. 良好的沟通协调能力，性格沉稳、细致耐心、为人正直，有很强的团队合作精神;',
      '6. 工作认真细致，积极主动，反馈迅速，有较高的工作效率.'
    ],
    hot: false,
  },
  {
    classfilyFmt: '管理类',
    classfily: 6, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '销售经理',
    experience: '-',
    school: '本科',
    salary: '15-25K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1. 负责根据公司战略目标，合理有效的拆分销售业绩目标；',
      '2. 负责管理直销（电销+面销）团队，完成团队业绩目标；',
      '3. 负责制订切实可行的销售开拓策略和计划，用于支撑销售业绩短期和长期的快速增长；',
      '4. 负责城市下属团队的搭建及优化管理；',
      '5. 负责团队人员招聘管理，严格按照公司标准把控质量，确保团队的健康发展；',
      '6. 能接受出差；'
    ],
    JobSpe: [
      // 任职要求
      '1. 3年以上销售团队管理经验；',
      '2. 具有较强的团队管理经验和领导能力；',
      '3. 具有较强的销售能力、敏锐的市场嗅觉及较强的市场拓展和销售能力；',
      '4. 具有较强的抗压性及团队凝聚力；',
      '5. 具有良好的职业素养和敬业精神；',
      '6. 能够接受异地出差。'
    ],
    hot: false,
  },
  {
    classfilyFmt: '其他',
    classfily: 7, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
    name: '司机',
    experience: '5-10年',
    school: '高中',
    salary: '6-7K',
    address: '北京',
    JobRes: [
      // 工作职责
      '1.负责保障公司领导及日常用车畅通； ',
      '2. 认真执行车辆管理的各项规章制度；',
      '3. 负责车辆保养、维修、保险及意外事故的处理；',
      '4. 爱护车辆装备，保持车容整洁、车况良好',
      '5. 记录车辆的行驶路线及行程；',
    ],
    JobSpe: [
      // 任职要求
      '1、高中及以上学历,年龄28-36岁左右；',
      '2. 五年以上驾龄，安全驾驶无事故记录，熟悉上北京以及周边地区主要道路；',
      '3、具有良好的驾驶技术和安全、服务及保密意识，身体健康、为人正直、踏实，工作积极主动；',
      '4、话少、无不良嗜好，不抽烟，能适应夜间加班；',
      '5、车辆情节和保养工作。',
      '退伍人员,党员或有公司领导司机工作经验者优先考虑；',
    ],
    hot: false,
  },
  // {
  //   hot: true,
  //   classfily: 2, // 1全部 2 产品类 3设计类 4职能类 5研发类 6管理类 7其他
  //   classfilyFmt: '市场类',
  //   name: '战略研发总监',
  //   address: '北京',
  //   salary: '25-30 K',
  //   experience: '5-10年',
  //   school: '本科',
  //   JobRes: [
  //     '1、对部门所涉及的产业尤其是互联网的产业环境、关键要素、市场供需及竞争信息进行动态的持续跟踪和分析；',
  //     '2、主动开展各项研究项目以支撑战略发展部未来决策及战略规划；',
  //     '3、参与项目管理相关工作，主动承担一定的产品设计及产品创新工作并有效推动落地；',
  //     '4、公司重点项目的持续跟进；',
  //     '5、完成领导交付的其他工作。',
  //   ],
  //   JobSpe: [
  //     '1、30-35岁，5-10年及以上相关行业研究员、分析师相关工作经验, 985、211院校研究生或具备互联网及电商相关工作经验者优先；',
  //     '2、有良好的沟通能力、文案功底良好、理解能力强、有技术背景；',
  //     '3、具备一定的财务及金融能力，并可接受出差安排；',
  //     '4、具备较强的分析能力，掌握成熟的研究方法论，能够独立制定研究计划及完成调研工作；',
  //     '5、具备较强的创新思维能力及持之以恒的毅力，能够主动积累、思考及探索创新方向。',
  //     '6、优秀的职业操守，坚韧不拔，具备高度的工作责任心和团队合作意识。',
  //     '7、具有良好的文案撰写能力及英文听、说、读、写能力，参与涉外商务及外联工作的沟通；',
  //   ],
  // },
]

function PositionTable(props) {
  let route = useHistory()

  const { levelOneTitle, levelOne } = props
  const [tableList, setTableList] = useState([])
  useEffect(() => {
    if (Number(levelOne) !== 1) {
      console.log('table Props', levelOneTitle, levelOne)
      const filterList = []
      tableData.forEach((item) => {
        console.log(item)
        if (Number(item.classfily) === Number(levelOne)) {
          filterList.push(item)
        }
      })
      setTableList(filterList)
    } else {
      setTableList(tableData)
    }
  }, [levelOneTitle, levelOne])
  const jump = (item) => {
    route.push({
      pathname: '/joinusform',
      state: { id: item.classfily, title: item.classfilyFmt },
    })
  }
  return (
    <>
      <div className="position-table-container pc">
        <p className="table-title">
          <span className="classfily">{levelOneTitle}</span>共
          <span className="classfily">{tableList.length}</span>个在招职位
        </p>
        <ul className="table">
          <li className="title table-col">
            {tableTitle.map((item, index) => {
              return (
                <div className="border" key={index}>
                  {item.name}
                </div>
              )
            })}
          </li>
          {/* onChange={callback} */}
          <Collapse>
            {tableList.map((item, index) => {
              return (
                <Panel
                  header={
                    <li className="table-col">
                      <div className="border">
                        {item.hot ? (
                          <img src={hot} alt="" className="hot-img-pc" />
                        ) : (
                          ''
                        )}

                        {item.name}
                        <DownOutlined className="icon-down" />
                      </div>
                      <div className="border">{item.classfilyFmt}</div>
                      <div className="border">{item.address}</div>
                      <div className="border">{item.salary}</div>
                      <div className="border">{item.experience}</div>
                      <div className="border">{item.school}</div>
                    </li>
                  }
                  showArrow={false}
                  key={index}
                >
                  <p className="pc-title">工作职责</p>
                  {item.JobRes && item.JobRes.length ? (
                    <ul className="job-list">
                      <p>岗位职责</p>
                      {item.JobRes.map((ite, ind) => {
                        return <li key={ind}>{ite}</li>
                      })}
                    </ul>
                  ) : null}
                  {item.JobSpe && item.JobSpe.length ? (
                    <ul className="job-list">
                      <p>任职要求</p>
                      {item.JobSpe.map((ite, ind) => {
                        return <li key={ind}>{ite}</li>
                      })}
                    </ul>
                  ) : null}

                  <Button
                    type="primary"
                    className="jump-btn"
                    onClick={() => {
                      jump(item)
                    }}
                  >
                    申请职位
                  </Button>
                </Panel>
              )
            })}
          </Collapse>
        </ul>
      </div>
      <div className="position-table-container h5">
        <p className="table-title">
          <span className="classfily">{levelOneTitle}</span>共
          <span className="classfily">{tableList.length}</span>个在招职位
        </p>
        <Collapse bordered={false} className="h5-collapse">
          {tableList.map((item, index) => {
            return (
              <Panel
                header={
                  <div className="h5-panel">
                    {item.hot ? (
                      <img src={hot} alt="" className="hot-img-h5" />
                    ) : (
                      ''
                    )}

                    <span>{item.name}</span>
                  </div>
                }
                key={index}
              >
                <div className="item-content">
                  <div className="item">
                    <span className="label">职位类型</span>
                    <span className="value">{item.classfilyFmt}</span>
                  </div>
                  <div className="item">
                    <span className="label">工作地点</span>
                    <span className="value">{item.address}</span>
                  </div>
                  <div className="item">
                    <span className="label">薪资待遇</span>
                    <span className="value">{item.classfilyFmt}</span>
                  </div>
                  <div className="item">
                    <span className="label">工作经验</span>
                    <span className="value">{item.experience}</span>
                  </div>
                  <div className="item">
                    <span className="label">学历</span>
                    <span className="value">{item.school}</span>
                  </div>
                </div>
                {item.JobRes && item.JobRes.length ? (
                  <div className="jobres-h5">
                    <p className="title">岗位职责</p>
                    <ul className="content">
                      {item.JobRes.map((ite, ind) => {
                        return (
                          <li className="content-item" key={ind}>
                            {ite}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                ) : null}
                {item.JobSpe && item.JobSpe.length ? (
                  <div className="jobspe-h5">
                    <p className="title">任职要求</p>
                    <ul className="content">
                      {item.JobSpe.map((ite, ind) => {
                        return (
                          <li className="content-item" key={ind}>
                            {ite}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                ) : null}

                <Button
                  type="primary"
                  className="jump-btn-h5"
                  onClick={() => {
                    jump(item)
                  }}
                >
                  申请职位
                </Button>
              </Panel>
            )
          })}
        </Collapse>
      </div>
    </>
  )
}
export default PositionTable
