import { connect } from 'react-redux'
import Back from '../../components/Back'
import Foot from '../../components/Foot'
import { PageRenderFixed, mapState, mapDispatch } from '../../utils/scroll'
import './index.scss'

function ProductDetail(props) {
  PageRenderFixed(props, 1)
  const data1 = {
    title: '云原生应用平台',
    text: 'KCAP-云原生应用平台（Kupu CloudNative Application Platform，KCAP）为企业提供服务化、高弹性、可观测性、过程自动化的PaaS平台，帮助企业提升云原生应用生命周期管理的效率，同时支持容器管理、CI/CD、微服务、ServiceMesh、API网关等全栈云原生能力。',
    summary: {
      title: '功能特性',
      list: [
        {
          title: '多集群管理',
          text: '多集群统一管理、分配'
        },
        {
          title: '日志、监控',
          text: 'operator 管理模式 ｜ ⽇志统计分析、清理 ｜ 全平台服务监控，提供历史统计'
        },
        {
          title: '资源管控',
          text: '平台导航统⼀融合 ｜ 配额、资源审批流程'
        },
        {
          title: '应用模版',
          text: '⽀持导⼊原⽣ helm 模版 ｜ 内置丰富常⽤模版 ｜ 模版克隆、下载、修改'
        }
      ]
    }
  }
  const data2 = {
    title: '云原生数据平台',
    text: '云原生数据平台（KupuData™）提供一站式可视化数据建设和数据开发平台，集容器云、数据汇聚、数据规划、数据开发、数据智能、数据资产、数据服务等功能于一体。解决中大企业面临的数据孤岛、数据维护困难、数据价值利用低的问题。帮助企业让数据成为资产。',
    summary: {
      title: '功能特性',
      list: [
        {
          title: '数据开发',
          text: '可视化一站式离线和实时开发平台，集成主流计算引擎和针对各种业务场景的存储引擎，通过拖拉拽的方式轻松完成数据处理分析流程。'
        },
        {
          title: '算法开发',
          text: '可视化训练及算法开发工具集成'
        },
        {
          title: '数据建设',
          text: '基于数据规划、数据治理的闭环管理理论，通过数据模型设计、指标建设、标签管理等工具构建统一的企业数据体系'
        },
        {
          title: '数据服务',
          text: '实现数据服务 API 的创建、维护、发布、监控等全生命周期的管理'
        }
      ]
    }
  }
  const data3 = {
    title: '自研数据库',
    text: '数智方自研数据库为企业打造 更可靠、安全、高性能、便捷 运维的数据库服务，\n' +
      '满足大 型企业对分布式事务一致 性、数据安全、高可用容灾 等多方面的要求，\n' +
      '保障云上 数据库的安全，实现用户的 数据库无缝迁云。',
    summary: {
      title: '',
      list: [

      ]
    }
  }
  console.log('props', props.history, props.history.location)
  const search = window.location.search.substring(1) // 获取携带的参数
  const arr = (search || '').split(/\?|\/|\&|\=/g)
  const searchObj = {}
  for(let i = 0; i < arr.length; i += 2){
    searchObj[arr[i]] = arr[i+1]
  }
  let data = null
  if (searchObj.id === '2') {
    data = data2
  } else if (searchObj.id === '3') {
    data = data3
  } else {
    data = data1
  }
  return (
    <>
      <div className='container-ProductDetail'>
        <Back path='/' />
        <div className='content'>
          <div className='article-title'>{ data.title }</div>
          <div className='article-text'>{ data.text }</div>
          <div>
            <div className='summary-title'>{ data.summary.title }</div>
            <div className='summary-list'>
              {
                data.summary.list.map((item, i) => {
                  return <div className='item' key={i}>
                    <div>{ item.title }</div>
                    <div>{ item.text }</div>
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </div>
      <Foot />
    </>
  )
}

export default connect(mapState, mapDispatch)(ProductDetail)
