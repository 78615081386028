import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import {EffectFade, Navigation } from "swiper";
import leftBottom from './img/leftBottom.png'
import leftTop from './img/leftTop.png'
import rightTop from './img/rightTop.png'
import rightBottom from './img/rightBottom.png'
import item1 from './img/item1.png'
import item2 from './img/item2.png'
import item3 from './img/item3.png'
import HomeTitle from '../../../components/HomeTitle'



import './index.scss'
function IndustryInformation() {
  let route = useHistory()
  const breakpointsOption = {
    1300: {  //当屏幕宽度大于等于1441
      slidesPerView: 3,
      spaceBetween: 24
    },
    851: {
      slidesPerView: 2.5,
      spaceBetween: 24
    },
    760: {
      slidesPerView: 1.8,
      spaceBetween: 20
    },
    0: {
      slidesPerView: 1.3,
      spaceBetween: 16
    }
  }
  const data= [
    {
      img: item1,
      time: '2022.01.11',
      title: '印尼全球竞争力排名提升至第37位',
      content: '专家们将印尼最近在全球竞争力排名中的进步归因于基础设施的改善、互联网用户和廉价劳动力数量的增加，而不是旨在加强国内产业的《创造就业综合法》。在COVID-19疫情...',
      jumpParams: 1
    },
    {
      img: item2,
      time: '2021.09.23',
      title: '80年代后，印尼在国内外市场的对接中做了哪三方面工作？',
      content: '印尼现有劳动力8640万(1993年)，同时每年还将有250万新增就业人数，由于目前印尼经济发展总体水平仍很低，全国大多数劳动人口主要集中在农业（占总劳动人口的一半以上)和服务贸易业（占总就业人口的1/3），而制造业就...',
      jumpParams: 2
    },
    {
      img: item3,
      time: '2021.03.04',
      title: '调查：印尼公司仍在持续招聘，蓄势待发等待疫情后时代',
      content: '尽管大多数企业被迫缩减规模并停止招聘新员工，但最近的一项调查发现，一些公司仍在继续招聘新员工，作为其在疫情危机结束后更快恢复战略的一部分。人力资源和财务解决方案初创公司 Mekari 最近发布的一项为期一个月...',
      jumpParams: 3
    }
  ]
  const jumpTo = (params) => {
    route.push({pathname: '/newsdetail', state: {id: params} })
  }
  return (
    <div className='information-container'>
      <div className="left-top">
        <img src={leftTop} alt="" />
      </div>
      <div className="left-bottom">
        <img src={leftBottom} alt="" />
      </div>
      <div className="right-top">
        <img src={rightTop} alt="" />
      </div>
      <div className="right-bottom">
        <img src={rightBottom} alt="" />
      </div>
      <HomeTitle titleTips='DYNAMIC NEWS' title='行业资讯' />
        <Swiper
        slidesPerView={"auto"}
        // slidesPerView={1.5}
        // spaceBetween={24}
        mousewheel={true}
        navigation={true}
        breakpoints = {breakpointsOption}
        modules={[EffectFade, Navigation]}
        className="information-swiper"
      >
        {
          data.map((item, index) => {
            return  <SwiperSlide className="swiper-item" key={index} onClick={() => jumpTo(item.jumpParams)}>
              <div className="item-warp">
                <div className="item-img">
                  <img src={item.img} alt="" />
                </div>
                <div className="item-time">
                  <div className="time">
                    {item.time}
                  </div>
                </div>
                <div className="item-title">
                  {item.title}
                </div>
                <div className="item-content">
                  {item.content}
                </div>
                <div className="jump">
                </div>
              </div>
            </SwiperSlide>
          })
        }
      </Swiper>
    </div>
  )
}

export default IndustryInformation
