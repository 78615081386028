import React, { useState, useEffect } from 'react'
import './index.scss'
import banner from '../../static/jionUs/banner.png'
import bannerH5 from '../../static/jionUs/banner-h5.png'

import PositionTable from '../../components/positionTable'
import { Menu, Dropdown } from 'antd'
// import { CaretRightOutlined } from '@ant-design/icons'
import caretRight from '../../static/caret_right.png'
const levelOneData = [
  {
    label: '全部',
    value: 1,
  },
  {
    label: '产品类',
    value: 2,
  },
  {
    label: '设计类',
    value: 3,
  },
  {
    label: '职能类',
    value: 4,
  },
  {
    label: '研发类',
    value: 5,
  },
  {
    label: '管理类',
    value: 6,
  },
  {
    label: '其他',
    value: 7,
  },
]

function PositionList(props) {
  const [levelOne, setLevelOne] = useState(1)
  const [levelOneTitle, setLevelOneTitle] = useState('全部')

  const levelOneClick = (item) => {
    console.log(item, 'item1')
    setLevelOneTitle(item.label)
    setLevelOne(item.value)
  }
  useEffect(() => {
    console.log('数据变化')
  }, [levelOne])
  const menu = (
    <Menu>
      {levelOneData.map((item, index) => {
        return (
          <Menu.Item key={index}>
            <div
              className={[
                Number(item.value) === Number(levelOne) ? 'active' : '',
              ].join(' ')}
              onClick={() => {
                levelOneClick(item)
              }}
            >
              {item.label}
            </div>
          </Menu.Item>
        )
      })}
    </Menu>
  )
  return (
    <>
      <div className="position-list-container">
        <div className="banner">
          <img src={banner} alt="" className="pc" />
          <img src={bannerH5} alt="" className="h5" />
          <div className="title-warp">
            <p className="title">研发岗位 火热招聘中</p>
            <p className="tips">R&D positions are hotly recruiting</p>
          </div>
        </div>
        <div className="filter-warp">
          <ul className="filter-level1-warp filter-list">
            <li className="filter-level1-title">
              <span>职位类别：</span>
            </li>
            {levelOneData.map((item, index) => {
              return (
                <li
                  className={[
                    'filter-level1-content',
                    Number(item.value) === Number(levelOne) ? 'active' : '',
                  ].join(' ')}
                  key={index}
                  onClick={() => {
                    levelOneClick(item)
                  }}
                >
                  <span>{item.label}</span>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="h5-dorw-down">
          <Dropdown
            overlay={menu}
            className="dropdown"
            trigger={['click']}
            overlayClassName="position-overlay"
          >
            <div
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <div className="dorp-title">
                <span>{levelOneTitle}</span>
                <div className="icon-img">
                  <img src={caretRight} alt="" />
                </div>
              </div>
            </div>
          </Dropdown>
        </div>
        <PositionTable
          levelOneTitle={levelOneTitle}
          levelOne={levelOne}
        ></PositionTable>
      </div>
    </>
  )
}

export default PositionList
