import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import logo from './img/logo.png'
import logo2 from './img/logo2.png'
import nav from './img/nav.png'
import nav2 from './img/nav2.png'
import './index.scss'
import { changeIndex } from '../../store/actions'

function Header(props) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [pathname, setPathName] = useState('/')
  const [show, setShow] = useState(true)
  const [showNav, setShowNav] = useState(false)
  const { dispatchChangeIndex } = props
  useEffect(() => {
    //初次加载挂载滚动事件
    window.addEventListener('scroll', handleScroll)
  }, [])
  useEffect(() => {
    // 每次切换路由滚动到顶部
    // 路由切换 设置为0
    dispatchChangeIndex(0)
    if (document && props?.location?.pathname !== '/') {
      // 可以排除不需要置顶的页面
      if (document?.documentElement || document?.body) {
        document.documentElement.scrollTop = document.body.scrollTop = 0 // 切换路由时手动置顶
      }
    }
  }, [props.location.pathname])
  useEffect(() => {
    setPathName(() => props.location.pathname)
    setShowNav(() => false)
    // const {changeIndex} = props;
  }, [props.location.pathname])
  useEffect(() => {
    setActiveIndex(() => props.activeIndex)
  }, [props.activeIndex])
  useEffect(() => {
    if (pathname === '/') {
      if (activeIndex === 0) {
        setShow(() => true)
      } else {
        setShow(() => false)
      }
    } else {
      if (activeIndex === 0) {
        setShow(() => true)
      } else {
        setShow(() => false)
      }
    }
  }, [pathname, activeIndex])
  const handleScroll = (e) => {
    const scrollTop = e.target.scrollingElement.scrollTop
    if (Number(scrollTop) <= 50) {
      dispatchChangeIndex(0)
    } else {
      dispatchChangeIndex(1)
    }
  }
  function handleShowNav() {
    if (showNav) {
      setShowNav(() => false)
    } else {
      setShowNav(() => true)
    }
  }

  if (show) {
    return (
      <>
        <div className="header">
          <Link className="logos" to="/">
            <img className="logo" src={logo} alt="" />
          </Link>
          <div className="header-nav">
            <Link className={pathname === '/' ? 'active' : ''} to="/">
              关于数智方
            </Link>
            <Link
              className={pathname === '/product' ? 'active' : ''}
              to="/product"
            >
              我们的产品
            </Link>
            <Link className={pathname === '/news' ? 'active' : ''} to="/news">
              新闻资讯
            </Link>
            <Link
              className={pathname === '/joinus' ? 'active' : ''}
              to="/joinus"
            >
              加入我们
            </Link>
            {/*<div className="change-lan">*/}
            {/*  <span>中文</span>&nbsp;&nbsp;/&nbsp;&nbsp;<span>EN</span>*/}
            {/*  &nbsp;&nbsp;/&nbsp;&nbsp;<span>IN</span>*/}
            {/*</div>*/}
          </div>
          <div className="header-mobile">
            <img className="nav" onClick={handleShowNav} src={nav} alt="" />
            <div
              className={
                showNav ? 'header-nav-mobile shows' : 'header-nav-mobile'
              }
            >
              <Link className={pathname === '/' ? 'active' : ''} to="/">
                关于数智方
              </Link>
              <Link
                className={pathname === '/product' ? 'active' : ''}
                to="/product"
              >
                我们的产品
              </Link>
              <Link className={pathname === '/news' ? 'active' : ''} to="/news">
                新闻资讯
              </Link>
              <Link
                className={pathname === '/joinus' ? 'active' : ''}
                to="/joinus"
              >
                加入我们
              </Link>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="header2">
          <Link className="logos" to="/">
            <img className="logo" src={logo2} alt="" />
          </Link>
          <div className="header-nav">
            <Link className={pathname === '/' ? 'active' : ''} to="/">
              关于数智方
            </Link>
            <Link
              className={pathname === '/product' ? 'active' : ''}
              to="/product"
            >
              我们的产品
            </Link>
            <Link className={pathname === '/news' ? 'active' : ''} to="/news">
              新闻资讯
            </Link>
            <Link
              className={pathname === '/joinus' ? 'active' : ''}
              to="/joinus"
            >
              加入我们
            </Link>
            {/*<div className="change-lan">*/}
            {/*  <span>中文</span>&nbsp;&nbsp;/&nbsp;&nbsp;<span>EN</span>*/}
            {/*  &nbsp;&nbsp;/&nbsp;&nbsp;<span>IN</span>*/}
            {/*</div>*/}
          </div>
          <div className="header-mobile">
            <img className="nav" onClick={handleShowNav} src={nav2} alt="" />
            <div
              className={
                showNav ? 'header-nav-mobile shows' : 'header-nav-mobile'
              }
            >
              <Link className={pathname === '/' ? 'active' : ''} to="/">
                关于数智方
              </Link>
              <Link
                className={pathname === '/product' ? 'active' : ''}
                to="/product"
              >
                我们的产品
              </Link>
              <Link className={pathname === '/news' ? 'active' : ''} to="/news">
                新闻资讯
              </Link>
              <Link
                className={pathname === '/joinus' ? 'active' : ''}
                to="/joinus"
              >
                加入我们
              </Link>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapState = (state) => ({
  activeIndex: state.activeIndex,
})
const mapDispatch = (dispatch) => ({
  dispatchChangeIndex(index) {
    dispatch(changeIndex(index))
  },
})
export default connect(mapState, mapDispatch)(withRouter(Header))
