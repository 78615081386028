import './index.scss'
function HomeTitle(props) {
  const { titleTips, title } = props;
  return (
    <div className='title-container'>
      <p className='title-tips'>{ titleTips }</p>
      <h1 className='title'>{ title }</h1>
    </div>
  )
}

export default HomeTitle;
