import { connect } from 'react-redux';
import  { changeIndex }  from '../../store/actions'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Mousewheel, Navigation, EffectFade } from "swiper";
import "./index.css";

//component
import Banner from './Banner'
import CompanyProfile from './CompanyProfile'
import IndustryInformation from './industryInformation'
import Development from './Development'
import Technology from './Technology'
import Foot from '../../components/Foot'
import React from "react";
class Home extends React.Component {

  render() {
    const { changeIndex } = this.props;
    const companyIndex = this.props.location.companyIndex
    const propsHomeIndex = this.props.location.homeIndex
    const changeIn =(swiper) =>{
      changeIndex(swiper.activeIndex)
    }
    const initSwiper = (swiper) => {
      // console.log(swiper, 999888, propsHomeIndex)
      swiper.slideTo(propsHomeIndex || 0)
    }
    return (
      <>
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={0}
          speed={1000}
          mousewheel={true}
          navigation={true}
          onInit={initSwiper}
          onSlideChangeTransitionEnd={changeIn}
          modules={[Mousewheel, Navigation, EffectFade]}
          className="mySwiper"
          id='mySwiperId'
        >
          <SwiperSlide>
            <Banner />
          </SwiperSlide>
          <SwiperSlide>
            <CompanyProfile index={ companyIndex } />
          </SwiperSlide>
          <SwiperSlide>
            <Development />
          </SwiperSlide>
          <SwiperSlide>
            <Technology />
          </SwiperSlide>
          <SwiperSlide>
          <IndustryInformation></IndustryInformation>
          </SwiperSlide>
          <SwiperSlide>
            <Foot />
          </SwiperSlide>
        </Swiper>
      </>
    )
  }
}

const mapState = (state) => ({
  activeIndex: state.activeIndex
});
const mapDispatch = (dispatch) => ({
  changeIndex(index) {
    dispatch(changeIndex(index));
  }
});
export default connect(mapState, mapDispatch)(Home);
