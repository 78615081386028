import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Foot from '../../components/Foot'
import HeadImg from '../../components/HeadImg'
import { PageRender, mapState, mapDispatch } from '../../utils/scroll'
import banner1 from '../../static/news/banner.png'
import banner_mobile from '../../static/news/banner_mobile.png'
import item1 from '../../static/news/item1.png'
import item2 from '../../static/news/item2_detail1.png'
import item3 from '../../static/news/item3_detail1.png'
import './index.scss'

function News(props) {
  const route = useHistory()
  PageRender(props)
  const isMobile = window.innerWidth <= 760
  const data = {
    banner: {
      img: banner1,
      imgMobile: banner_mobile,
      title: '新闻资讯',
      titleTranslate: 'NEWS INFORMATION'
    },
    list: [
      {
        id: 1,
        day: '20',
        yearMonth: '2022.01',
        date: '2022.01.11',
        title: '印尼全球竞争力排名提升至第37位',
        text: '专家们将印尼最近在全球竞争力排名中的进步归因于基础设施的改善、互联网用户和廉价劳动力数量的增加，而不是旨在加强国内产业的《创造就业综合法》。<br />在COVID-19疫情沉重打击全球经济的背景下，印尼在国际管理发展学院（IMD）...',
        img: item1
      },
      {
        id: 2,
        day: '20',
        yearMonth: '2021.09',
        date: '2021.09.23',
        title: '80年代后，印尼在国内外市场的对接中做了哪三方面工作？',
        text: '印尼现有劳动力8640万(1993年)，同时每年还将有250万新增就业人数，由于目前印尼经济发展总体水平仍很低，全国大多数劳动人口主要集中在农业（占总劳动人口的一半以上)和服务贸易业（占总就业人口的1/3），而制造业就业人口不足劳动人口的...',
        img: item2
      },
      {
        id: 3,
        day: '20',
        yearMonth: '2021.03',
        date: '2021.03.04',
        title: '印尼公司仍在持续招聘，蓄势待发等待疫情后时代',
        text: '尽管大多数企业被迫缩减规模并停止招聘新员工，但最近的一项调查发现，一些公司仍在继续招聘新员工，作为其在疫情危机结束后更快恢复战略的一部分。<br />人力资源和财务解决方案初创公司 Mekari 最近发布的一项为期一个月的调查显示...',
        img: item3
      }
    ]
  }
  const goDetail = (id) => {
    if (isMobile) {
      route.push({pathname: '/newsdetail', state: {id: id} })
    }
  }
  return (
    <>
      <div className='container-News'>
        <HeadImg src={data.banner.img} mobile={data.banner.imgMobile} title={data.banner.title} titleTranslate={data.banner.titleTranslate} />
        {
          data.list.map((item, i) => {
            return <div className={ i % 2 === 1 ? 'item reverse' : 'item' } key={i} onClick={() => goDetail(item.id)}>
              <div className='content-pc'>
                <div className='date'>
                  <span>{ item.day }</span>
                  <span>{ item.yearMonth }</span>
                </div>
                <div className='title'>{ item.title }</div>
                <div className='text' dangerouslySetInnerHTML={{ __html: item.text }} />
                <Link className='button' to={{ pathname: '/newsdetail', state: {id: item.id } }}>
                  <span>查看</span>
                  <img src={require('../../static/right_arrow.png')} alt=""/>
                </Link>
              </div>
              <div className='content-mobile'>
                <div className='title'>{ item.title }</div>
                <div className='date'>{ item.title }</div>
                <div className='text' dangerouslySetInnerHTML={{ __html: item.text }} />
                <Link className='button' to={{ pathname: '/newsdetail', state: {id: item.id } }}>
                  <span>查看</span>
                  <img src={require('../../static/right_arrow.png')} alt=""/>
                </Link>
              </div>
              <img src={item.img} alt="" className='item-img' />
            </div>
          })
        }
      </div>
      <Foot />
    </>
  )
}

export default connect(mapState, mapDispatch)(News)
