import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Mousewheel} from "swiper";
import "swiper/css/navigation";
import './index.scss'
import t1 from './img/t1.png'
import t2 from './img/t2.png'
import t3 from './img/t3.png'
import t4 from './img/t4.png'
import t5 from './img/t5.png'
import t6 from './img/t6.png'
import t1m from './img/t1m.png'
import t2m from './img/t2m.png'
import t3m from './img/t3m.png'
import t4m from './img/t4m.png'
import t5m from './img/t5m.png'
import t6m from './img/t6m.png'
import num from './img/num.png'

import HomeTitle from '../../../components/HomeTitle'

function Development() {
  const breakpointsOption = {
    1440: {
      slidesPerView: 2.5,
      spaceBetween: 24
    },
    1190: {
      slidesPerView: 2.5,
      spaceBetween: 24
    },
    960: {
      slidesPerView: 2.5,
      spaceBetween: 24
    },
    760: {
      slidesPerView: 1.7,
      spaceBetween: 20
    },
    0: {
      slidesPerView: 1.7,
      spaceBetween: 16
    }
  }
  const data = [
    {
      time: '现在',
      t1: t1,
      t2: t1m,
      address: '已达300万用户'
    },
    {
      time: '2021年9月',
      t1: t6,
      t2: t6m,
      address: 'KUPU正式投入印尼市场'
    },
    {
      time: '2021年3月',
      t1: t5,
      t2: t5m,
      address: '成立印尼运营中心'
    },
    {
      time: '2021年2月',
      t1: t4,
      t2: t4m,
      address: 'KUPU推出mvp版本'
    },
    {
      time: '2020年9月',
      t1: t3,
      t2: t3m,
      address: '成立北京研发中心'
    },
    {
      time: '2020年5月',
      t1: t2,
      t2: t2m,
      address: '获得金光集团亿元以上天使轮投资'
    }
  ]
  return (
    <>
      <div className='dev-container'>
        <HomeTitle titleTips='DEVELOPMENT PATH' title='发展历程'/>
        <Swiper
          breakpoints={breakpointsOption}
          navigation={true}
          centeredSlides={true}
          mousewheel={false}
          modules={[Navigation, Mousewheel]}
          className="mySwiper"
        >
          {
            data.map(item => {
              return (
                <SwiperSlide>
                  <div className="slide">
                    <h1 className="time">{ item.time }</h1>
                    <div className='img'>
                      <img className='t1' src={ item.t1 } alt=""/>
                      <img className='t2' src={ item.t2 } alt=""/>
                      <span></span>
                    </div>
                    <p className='address'><i></i> { item.address }</p>
                  </div>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </div>
    </>
  )
}

export default Development
