import {useHistory} from 'react-router-dom'
import se from '../../static/search.png'
import './index.scss'

function JoinUsHome() {
  let route = useHistory()
  const data = [
    {
      time: '2022.03.01',
      title: '产品经理',
      content: '北京/产品部',
      id: 2
    },
    {
      time: '2022.03.01',
      title: '安卓开发工程师',
      content: '北京/研发部',
      id: 5
    },
    {
      time: '2022.02.25',
      title: '测试工程师',
      content: '北京/研发部',
      id: 6
    }
  ]
  const jumpTo = (params) => {
    route.push({pathname: '/joinusform', state: {id: params.id, title: params.title}})
  }
  const jumpToList = () => {
    route.push({ pathname: '/positionlist' })
  }
  const search = () => {
    route.push({ pathname: '/positionlist' })
  }
  return (
    <div className="join-container">
      <div className='slog'>
        <p className='ch'>和优秀的人 做有挑战的事</p>
        <p className='en'>
          <i className='left'></i>
          <span>JOIN US</span>
          <i className='right'></i>
        </p>
        <div className='search'>
          <input type="text" placeholder='输入职位进行搜索'/>
          <span onClick={ () => { search() }}><img src={ se } alt=""/></span>
        </div>
      </div>
      <div className='swiper-container'>
        <div className="swiper-left">
          <p className='title-tips'>JOIN <br/> US</p>
          <p className='title'>
            热招岗位
          </p>
        </div>
        <div className='swiper-right'>
          {
            data.map(item => {
              return (
                  <div className='item' onClick={() => {jumpTo(item)}}>
                    <h1 className='title'>{item.title}</h1>
                    <p className='content'>{item.content}</p>
                    <p className='time'>{item.time}</p>
                  </div>
              )
            })
          }
          <div className='item more' onClick={() => {jumpToList()}}>
            <p>查看更多</p>
            <img src="" alt=""/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JoinUsHome;
