import './index.scss'
// import { useEffect, useRef, useState } from 'react'
import { useEffect, useState } from 'react'
import { Anchor } from 'antd'
import kupuLogo from '../../static/product/kupuLogo.png'
import kupuApp from '../../static/product/kupuApp.png'
import kupuTeach from '../../static/product/kupu-teach.png'
import kupuBI from '../../static/product/kupuBI.png'
import kupuMan from '../../static/product/kupuMan.png'
import kupuKosApp from '../../static/product/kupuKosApp.png'
import banner from '../../static/product/banner.png'
import bannerMobile from '../../static/product/banner_mobile.png'
import Foot from '../../components/Foot'
import HeadImg from '../../components/HeadImg'

const { Link } = Anchor
const enumData = [
  {
    icon: kupuLogo,
    detailImg: kupuApp,
    title: 'KUPU招聘平台',
    twoTitle: '蓝领灵活用工共享平台',
    tips: `KUPU专注服务于东南亚蓝领及零工市场，促进就业多元化，实现更灵活的劳动力配置，改善就业环境与提高就业率，推动数字经济时代蓝领技能型人才培育，满足东南亚新经济增长方式的需要。KUPU优先进入印尼市场，提供人才匹配、工作管理、薪酬结算、技能培训等服务，协助企业搭建数字化协同办公环境，满足零售、餐饮等领域企业灵活应对快速变化的商业环境和业务发展需求，实现降本增效与弹性运营。<a href="https://www.kupu.id/" target="_blank">https://www.kupu.id/</a> `,
    list: [
      {
        title: '用户：',
        value: '30W+',
      },
      {
        title: '整体体验评分：',
        value: '4.5/5',
      },
      {
        title: '印尼灵活用工领域App：',
        value: 'Top3',
      },
    ],
    anchor: 'kupu-recruitment',
  },
  {
    icon: kupuLogo,
    detailImg: kupuTeach,
    title: 'KUPU学院',
    twoTitle: '蓝领用工知识共享平台',
    tips: 'KUPU 学院致力于为蓝领工人和内容发布者打造职业技能学习的知识共享平台。内容创造者利用智能化的工具，制作，预览，发布课程。蓝领工人利用碎片化的时间，进行专业知识的学习和职业技能的提升。求职者的培训经历将通过勋章的形式向招聘者展示，作为职业能力的证明，提升求职成功率。另外通过引人入胜的游戏化方式为求职者提供在线的职业评估服务，获得专业的职业规划建议。',
    list: [
      {
        title: '课程单元：',
        value: '6000+',
      },
      {
        title: '课程试题：',
        value: '20,000+',
      },
      {
        title: '勋章级别：',
        value: '500+',
      },
    ],
    anchor: 'kupu-pc',
  },
  {
    icon: kupuLogo,
    detailImg: kupuBI,
    title: 'KUPU-BI后台管理系统',
    twoTitle: '后台数据实时监控',
    tips: '数据资产平台是数据资产采集、清洗、定义、加工、管理、服务的全流程平台，提供数据采集、数据治理、质量评估、分析建模、指标体系、标签构建、算法平台、数据服务等功能；帮助客户实现数据资源管理，潜在规律挖掘，业务决策优化。并且，结合算法平台，通过用户画像、运用NLP精准识别简历描述和职位需求，在保证人岗匹配准确率的前提下，智能识别人才与职位意图匹配。',
    list: [
      {
        title: '用户总数：',
        value: '32W+',
      },
      {
        title: '简历总数：',
        value: '30W+',
      },
      {
        title: '认证企业数：',
        value: '500+',
      },
    ],
    anchor: 'kupu-bi',
  },
  {
    icon: kupuLogo,
    detailImg: kupuMan,
    title: 'KUPU运营管理平台',
    twoTitle: 'KUPU蓝领用工业务运营管理平台',
    tips: 'KUPU运营管理平台致力于为KUPU运营者提供全面、便捷、高效的业务运营管理工具，方便运营者能更及时、更高效为入驻KUPU平台的雇主和求职者提供服务。运营人员可使用运营管理平台的用户运营管理、职位运营管理、简历运营管理、审核管理、功能配置等十数个功能模块分别对KUPU用户（雇主&求职者）、雇主发布的职位、求职者的简历、认证信息及App端运营工具等进行精细化运营管理，为雇主能更快招到合适的求职者、求职者更快找到合适的工作搭建更高效求职招聘平台！',
    anchor: 'kupu-man',
  },
  {
    icon: kupuLogo,
    detailImg: kupuKosApp,
    title: 'KUPU-KOS',
    twoTitle: '用户生命周期管理数据',
    tips: 'KOS的定位是帮助企业生态实现组织管理升级以及完善用户体验的运营平台，主要的价值在于用户生命周期的价值管理；通过自动化运营、商机管理、智能分析和匹配等功能，帮助JS（求职者）完善简历、邀请培训、组织面试等动作使得求职者更加“完美”;同时帮助企业客户培训、在线指导、上门咨询服务等方式，使得JP（雇主）在生态内更加的“便利”',
    list: [
      {
        title: '帮助求职者：',
        value: '8W+',
      },
      {
        title: '帮助雇主：',
        value: '7000+',
      },
      {
        title: '促成用工：',
        value: '19W+',
      },
    ],
    anchor: 'KUPU-KOS',
  },
]

function Product(props) {
  return (
    <div className="my-product-container">
      {/*<div className="banner">
        <img src={banner} alt="" />
      </div>*/}
      <HeadImg
        src={banner}
        mobile={bannerMobile}
        title="数立使命 智在远方"
        titleTranslate="DIGITAL MISSION, WISDOM IS IN THE DISTANCE"
        line
      />
      <div className="content-warp">
        <div className="col-3 content-left">
          <Anchor
            affix={false}
            offsetTop={80}
            targetOffset={100}
            bounds={0}
            className="anchor"
          >
            {enumData.map((item, index) => {
              return (
                <Link href={`#${item.anchor}`} title={item.title} key={index} />
              )
            })}
            {/* <Link href="#kupu-recruitment" title="KUPU招聘平台" />
            <Link href="#KUPU-BI" title="KUPU-BI"></Link>
            <Link href="#KUPU-KOS" title="KUPU-KOS" />
            <Link href="#qiyee-paas" title="齐蚁PaaS平台"></Link> */}
          </Anchor>
        </div>
        <div className="col-6 content-center">
          {enumData.map((item, index) => {
            return (
              <div id={item.anchor} key={index} className="item">
                <div className="title">
                  {item.icon && (
                    <div className="icon">
                      <img src={item.icon} alt="" />
                    </div>
                  )}
                  <div className="title-font">{item.title}</div>
                </div>
                <div className="two-title">{item.twoTitle}</div>
                <div className="tips" dangerouslySetInnerHTML={{__html: item.tips}}></div>
                {item.list && item.list.length ? (
                  <div className="data-list-warp">
                    {item.list.map((ite, ind) => {
                      return (
                        <div className="data-list" key={ind}>
                          <div className="label">{ite.title}</div>
                          <div className="value">{ite.value}</div>
                        </div>
                      )
                    })}
                    {/* <div className="data-list">
                    <div className="label">用户：</div>
                    <div className="value">{item.userNumber}</div>
                  </div>
                  <div className="data-list">
                    <div className="label">整体体验评分：</div>
                    <div className="value">{item.score}</div>
                  </div>
                  <div className="data-list">
                    <div className="label">可自定义修改：</div>
                    <div className="value">{item.custom}</div>
                  </div> */}
                  </div>
                ) : null}

                <div className="detail-img">
                  {item.detailImg && <img src={item.detailImg} alt="" />}
                </div>
                {
                  item.downLoad ?  <div className='down-load'>
                  <span>下载地址：</span>
                  <a  target="_blank" href={item.downLoad} rel="noreferrer">{item.downLoad}</a>
                </div> : null
                }

              </div>
            )
          })}
          {/* <div id="kupu-recruitment">kupu-recruitment</div>
          <div id="KUPU-KOS">KUPU-KOS</div>
          <div id="KUPU-BI">KUPU-BI</div>
          <div id="qiyee-paas">qiyee-paas</div> */}
        </div>
        <div className="col-3"></div>
      </div>
      <Foot />
    </div>
  )
}
export default Product
