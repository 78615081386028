import { connect } from 'react-redux'
import Back from '../../components/Back'
import Foot from '../../components/Foot'
import { PageRenderFixed, mapState, mapDispatch } from '../../utils/scroll'
import item1_detail1 from '../../static/news/item1_detail1.png'
import item2_detail1 from '../../static/news/item2_detail1.png'
import item2_detail2 from '../../static/news/item2_detail2.png'
import item3_detail1 from '../../static/news/item3_detail1.png'
import './index.scss'

function NewsDetail(props) {
  PageRenderFixed(props, 1)
  const data1 = {
    title: '印尼全球竞争力排名提升至第37位',
    date: '2022年1月11日',
    list: [
      {
        type: 'text',
        content: '专家们将印尼最近在全球竞争力排名中的进步归因于基础设施的改善、互联网用户和廉价劳动力数量的增加，而不是旨在加强国内产业的《创造就业综合法》。<br />' +
            '在COVID-19疫情沉重打击全球经济的背景下，印尼在国际管理发展学院（IMD）2021年世界竞争力排名中上升三位，位列第37名。'
      },
      {
        type: 'img',
        content: item1_detail1
      },
      {
        type: 'text',
        content: '在COVID-19疫情期间，印尼提升了国家竞争力。<br />' +
            '若干东南亚国家全球竞争力指数排名<br />' +
            '总部位于瑞士的IMD世界竞争力中心（IMD World Competitibility Center）自1989年以来每年利用统计数据和高管意见调查对64个经济体进行排名。'
      },
      {
        type: 'text',
        content: '印度尼西亚大学经济与商业学院（LM FEB UI）管理研究所的研究和咨询负责人Bayuadi Wibowo说，持续改进的税收政策和源源不断的廉价劳动力是印尼排名上升的主要原因。<br />' +
            '他补充说，虽然印尼在商业立法方面的排名（这是整体竞争力得分的一部分）从去年的第50名上升到了第40名，但中央和地方政府之间不同步的监管阻碍了营商环境的改善。<br />' +
            '“政府通过了《创造就业综合法》来简化监管，但这项法律本身又会衍生新的监管。政府不应该让这些新规定使商业运作复杂化。”<br />' +
            'LM FEB UI研究员Taufiq Nur表示，到目前为止，《创造就业综合法》的效果尚未在研究中得到体现，但高管意见调查表明对该立法持乐观态度。<br />' +
            '“这项立法相对较新，因此在2021年的影响并不显著。也许在2022年，在实施一年后，我们可以看到它的效果。”'
      }
    ]
  }
  const data2 = {
    title: '80年代后，印尼在国内外市场的对接中做了哪三方面工作？',
    date: '2021年9月23日',
    list: [
      {
        type: 'text',
        content: '引言<br />' +
            '印尼现有劳动力8640万(1993年)，同时每年还将有250万新增就业人数，由于目前印尼经济发展总体水平仍很低，全国大多数劳动人口主要集中在农业（占总劳动人口的一半以上)和服务贸易业（占总就业人口的1/3），而制造业就业人口不足劳动人口的10%。印尼劳动人口就业的一个重要特点是，全国相当一部分劳动者处于一种不稳定的就业状况，全国城市没有实现充分就业的人数约占总劳动人口的38%。'
      },
      {
        type: 'text',
        content: '印尼劳动人口的文化素养较高，农村文盲人数只占总人口的23%，而城市文盲人数则为9%。近年来随着经济发展及教育水平的提高，全国学龄前儿童入学率高达100%。印尼劳动人口的工资水平远远低于其他东盟国家。近年来，尽管印尼独立工会作出了巨大努力，工人工资略有上升，但平均每小时工资仍不超过0.5美元。'
      },
      {
        type: 'text',
        content: '最近几年，随着经济发展，印尼劳动力市场情况发生了较大变化。到本世纪末，农业方面就业人数将下降到劳动人口的1/3以下，而制造业方面的就业人数将达到总就业人数的1/4。'
      },
      {
        type: 'text',
        content: '房地产市场<br />' +
            '80年代中期以来，随着印尼经济的飞速发展，房地产业也突飞猛进，并成为其支柱性产业之一。但是，1991年印尼政府开始实施的紧缩银根政策对房地产业的发展产生了严重的冲击波，1992一1993年间，这一产业发展趋缓。1994年，印尼政府放宽了银行存贷款利率，同时在6月份还第一次允许外资进入房地产市场，加之国内经济发展继续保持较高的增长态势，房地产市场需求仍很旺盛。<br />' +
            '截至1994年底，雅加达等主要城市的写字楼出租率都在91%，月房租价格也稳定在12一15美元/每平方米。今后几年，印尼房地产业仍将保持这种发展态势，但市场竞争将更为激烈，房租价格也将稳中有降。'
      },
      {
        type: 'img',
        content: item2_detail1
      },
      {
        type: 'text',
        content: '国内外市场的对接<br />' +
            '印尼独立以来，在与国际市场对接问题上曾走过十分艰难曲折的道路。在苏加诺执政时期由于过分强调国家的自力更生，同时在处理与欧美国家关系时有些行为过于激烈，从而导致其经济发展与国际市场的脱节。苏哈托执政后，印尼与欧美国家重新建立了经济联系，但其经济发展战略仍以依都本国力量为主，即以高关税及一些非关税壁垒建立起强大的保护体系，由本国企业生产一些替代进口的产品。因此在一段时期内与国际市场的联系也十分有限。'
      },
      {
        type: 'text',
        content: '进入80年代中期以后，印尼政府调整了经济发展战略，并把发展外向型经济作为政府经济工作的重点加以扶持，其国内经济发展与国际市场的联系才日益强化。<br />' +
            '印尼政府加强与国际市场接轨的政策主要分三个方面：'
      },
      {
        type: 'text',
        content: '发展对外贸易<br />' +
            '1986年5月，印尼政府宣布免除出口型企业进口原材料的关税，同时在财政部专门设立了一个为外向型企业服务的机构，这大大降低了出口型企业的非生产性支出。1986年9月，政府宣布将印尼盾贬值45%，同时对外汇兑换率采取更为灵活的政策以刺激出口。同年10月，又宣布取消150个项目的进口税，免除165种进口许可证及放松对110个项目的进口许可证限制。'
      },
      {
        type: 'text',
        content: '1987年1月和12月，政府又两次宣布放宽进口许可证限制。1990年5月，宣布取消绝大部分非关税壁垒，降低各种关税税率，同时进一步简化出口贸易手续，保证货畅其流。此外，政府还通过举办订货会、展览会，进一步拓宽经贸渠道。<br />' +
            '印尼政府通过外贸体制改革，大大提高了本国产品在国际市场上的竞争力，特别是非石油、天然气产品出口已取得了长足的进步。1987年，非石油、天然气产品出口额便达到86亿美元，比上一年度增加了80%，以后又连年跃进，到198年更达到了270亿美元，占该年度总出口额的73%。'
      },
      {
        type: 'text',
        content: '大力吸引外资<br />' +
            '特别是80年代中期以来，多次对引进外资政策进行全面修改，制定了一系列鼓励和吸引外商投资的政策措施：'
      },
      {
        type: 'text',
        content: '简化投资程序，放宽投资领域。1987年6月，印尼政府宣布放宽申请投资和扩大投资领域的审批制度，同时将限制外国资本投资的项目由273个减少到75个，在这75个项目中，只有9项严格禁止外国资本涉足，其余66项则只需外销产品达到印尼政府规定的要求就可以投资。对于外商资本注册资金的最低限额也由100万美元减少到25万美元。'
      },
      {
        type: 'text',
        content: '政府在爪哇等地建立一些专供投资者使用的工业区，并在水电、交通等方面提供保证。为了吸引来自香港和台湾地区的投资，政府还破例允许在这些工业区开设华语学校。另外，政府还和新加坡方面合作，在巴淡地区开办一个试验性经济开发区，在那里实行优惠关税制，并对部分商品实行免税。税收优惠。印尼政府对于外向型投资项目大多给予税率优惠，包括减免资产税及原材料进口税。对于这些企业在印尼购买的原材料则减免增值税及各种附加税。'
      },
      {
        type: 'img',
        content: item2_detail2
      },
      {
        type: 'text',
        content: '结语<br />' +
            '这些政策措施大大改善了外商投资环境，使外商在印尼的投资迅速增加。截至1967一1994年，印尼共吸引外资891亿美元。其中，1986一1994年共吸引外资702亿美元，占吸引外资总额的79%。'
      }
    ]
  }
  const data3 = {
    title: '调查：印尼公司仍在持续招聘，蓄势待发等待疫情后时代',
    date: '2021年3月4日',
    list: [
      {
        type: 'img',
        content: item3_detail1
      },
      {
        type: 'text',
        content: '尽管大多数企业被迫缩减规模并停止招聘新员工，但最近的一项调查发现，一些公司仍在继续招聘新员工，作为其在疫情危机结束后更快恢复战略的一部分。'
      },
      {
        type: 'text',
        content: '人力资源和财务解决方案初创公司 Mekari 最近发布的一项为期一个月的调查显示，在 COVID-19 流行期间，大多数印度尼西亚公司要么冻结了招聘，要么减少了招聘。'
      },
      {
        type: 'text',
        content: 'Mekari 的人力资源信息系统 (HRIS) Talenta 收集了 3 月初实施社交距离之前仍在招聘的 558 家公司的数据。 其中，只有 410 人在 3 月 13 日至 4 月 13 日期间仍在招聘。'
      },
      {
        type: 'text',
        content: '它还发现，64% 的受访公司已将招聘目标从每月 7 名新员工减少到平均只有 2 名。'
      },
      {
        type: 'text',
        content: '同时，数据显示，25% 的受访企业在居家办公（WFH）政策出台前继续招聘，而 11% 的企业在 WFH 实施前后保持相同的招聘率。'
      },
      {
        type: 'text',
        content: '“尽管数量不大，但仍有一些公司在招聘员工，”Mekari 营销副总裁 Standie Nagadi表示。 “这是公司采取的战略步骤之一，以便在疫情大流行结束时能够更快地反弹，”他说。'
      },
      {
        type: 'text',
        content: 'Standie 表示，调查数据是汇总和匿名的，Mekari 没有获得客户内部数据的访问权限，无法识别在疫情期间仍在招聘的业务部门。'
      },
      {
        type: 'text',
        content: '“技术将在疫情后的‘新常态’中发挥不可或缺的作用。对可以帮助公司实施在家工作的技术的需求越来越大，[远程在线会议系统]，”他说。'
      },
      {
        type: 'text',
        content: '招聘冻结和减少使应届大学毕业生很难找到工作。 印度尼西亚统计局 (BPS) 显示，2019 年 8 月，该国 1300 万大学毕业生中约有 737,000 人失业，即 5.67%。 这仍然高于该国同期5.28%的整体失业率。'
      },
      {
        type: 'text',
        content: '人力部的数据显示，截至5月1日，约有 170 万正规和非正规工人失业。在正规部门，有 375,165 名工人被归类为 Pemutusan Hubungan Kerja (PHK)，即终止就业，而超过 100 万工人被 “pekerja dirumahkan”（无薪休假）。'
      },
      {
        type: 'text',
        content: '印度尼西亚工商会（Kadin）提出了鲜明的对比，估计至少有 600 万工人由于 COVID-19 流行病的经济影响而失业。'
      },
      {
        type: 'text',
        content: '一些初创企业还减少了招聘人数，并让员工在疫情爆发期间休无薪假以维持生计。 其他的，比如 Airy Rooms，已经永久关闭了他们的业务。'
      },
      {
        type: 'text',
        content: '职业发展平台和毕业生招聘网络 Glints Indonesia 的国家主管史蒂夫·苏坦托早些时候表示，企业在招聘新人才方面变得更加谨慎。他还指出，零售、旅游、航空和食品饮料行业受到的影响最大。'
      }
    ]
  }
  console.log('props', props.history, props.history.location)
  const state = props.history.location.state
  let data = null
  if (state.id === 2) {
    data = data2
  } else if (state.id === 3) {
    data = data3
  } else {
    data = data1
  }
  return (
    <>
      <div className='container-NewsDetail'>
        <Back path='/news' />
        <div className="content">
          <div className="title">{ data.title }</div>
          <div className="date">{ data.date }</div>
          <div className="line" />
          {
            data.list.map((item, i) => {
              return item.type === 'text' ?
                <div className='text' key={i} dangerouslySetInnerHTML={{ __html: item.content }} /> :
                <img src={item.content} key={i} alt="" className='img' />
            })
          }
        </div>
      </div>
      <Foot />
    </>
  )
}

export default connect(mapState, mapDispatch)(NewsDetail)
