import video from './img/video.png'
import videoMobile from './img/video-mobile.gif'
import './index.scss'
function Banner() {
  return (
    <div className="banner-container">
      <video className='video-pc' loop="loop" muted autoPlay playsInline x5-video-player-type="h5"  poster={video}>
          <source src="https://pic.kupu.id/web/video.mp4"/>
      </video>
      <img className='video-gif'  src={ videoMobile } alt=""/>
      <div className="content">
        <div className="top-title">
          我们的使命
        </div>
        <div className="center-title">通过数字化创新 <br/> 打造东南亚最先进的<span className="sp">蓝领用工平台</span>
        </div>
        <div className="bottom-title">
          KUPU专注服务于东南亚蓝领及零工市场，促进就业多元化，实现更灵活的劳动力配置，改善就业环境与提高就业率，推动数字经济时代蓝领技能型人才培育，满足东南亚新经济增长方式的需要。
        </div>
      </div>
    </div>
  )
}

export default Banner
