import * as constants from './constants'
const defaultState = {
  activeIndex: 0
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case constants.CHANGE_INDEX:
      return {
        ...state,
        activeIndex: action.activeIndex
      }
    default:
      return state
  }
}

export default reducer
