import axios from 'axios'

const service = axios.create({
  // import.meta.env.NODE_ENV === 'development' 来判断是否开发环境
  baseURL: '/',
  timeout: 15000
})


service.interceptors.request.use(
  config => {
    // 公共请求头
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(new Error(error))
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status === 200) {
      // 接口状态200
      if (Number(res.code) === 0 || response.config.notCode) { // 下载等不返回code的接口
        return res
      } else {
 
        return Promise.reject(response)
      }
    } else { // 接口状态异常
      // ElLoading.service().close()
      return Promise.reject(response)
    }
  },
  error => {
    return Promise.reject(error.response)
  })

export default service