// import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {Provider} from 'react-redux';
import store from './store'
import Header from './components/Header'
import Home from './pages/Home'
import JoinUsHome from './pages/JoinUsHome'
import Product from './pages/Product'
import News from './pages/News'
import NewsDetail from './pages/NewsDetail'
import ProductDetail from './pages/ProductDetail'
import PositionList from './pages/PositionList'
import JoinUs from './pages/JoinUs'


function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/product" component={Product}></Route>
          <Route exact path="/news" component={News}></Route>
          <Route exact path="/joinus" component={JoinUsHome}></Route>
          <Route exact path="/positionlist" component={PositionList}></Route>
          <Route exact path="/newsdetail" component={NewsDetail}></Route>
          <Route exact path="/productdetail" component={ProductDetail}></Route>
          <Route exact path="/joinusform" component={JoinUs}></Route>

          {/*<Route path='/product' exact element={<Product/>}></Route>*/}
          {/*<Route path='/news' exact element={<News/>}></Route>*/}
          {/*<Route path='/joinus' exact element={<JoinUs/>}></Route>*/}
          {/*<Route path='/newsdetail' exact element={<NewsDetail/>}></Route>*/}
          {/*<Route path='/productdetail' exact element={<ProductDetail/>}></Route>*/}
        </Switch>
      </BrowserRouter>
    </Provider>

  );
}

export default App;
